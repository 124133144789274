import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import jwt_decode from "jwt-decode";

import MainLayout from "../views/layout-main.vue";

import PageError from "../views/page-error.vue";
import PageNotFound from "../views/page-not-found.vue";

import ClientEstimate from "../views/client-estimate.vue";
import ClientEstimateSubmit from "../views/client-estimate-submit.vue";
import EstimatePrint from "../views/estimate-print.vue";

import AdminSignIn from "../views/admin-signin.vue";
import AdminEstimateList from "../views/admin-estimate-list.vue";
import AdminEstimateDetail from "../views/admin-estimate-detail.vue";

import AdminTestAnalysis from "../views/admin-test-analysis.vue";

import {getCookie, getSubdomain, removeCookie} from "../js/utils";
import {Paths} from "../js/types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: MainLayout,
    children: [
      {
        path: "/client/estimate",
        name: "client-estimate",
        component: ClientEstimate,
      },
      {
        path: "/admin/estimates",
        name: "admin-estimates",
        component: AdminEstimateList,
      },
      {
        path: "/admin/estimates/:estimateId",
        name: "admin-estimate-detail",
        component: AdminEstimateDetail,
      },
      {
        path: "/admin/test/analysis",
        name: "admin-test-analysis",
        component: AdminTestAnalysis,
      },
    ],
  },
  {
    path: "/client/estimate/submit",
    name: "client-estimate-submit",
    component: ClientEstimateSubmit,
  },
  {
    path: "/estimate/print",
    name: "estimate-print",
    component: EstimatePrint,
  },
  {
    path: "/admin",
    component: AdminSignIn,
  },
  {
    path: "/admin/signin",
    name: "admin-signin",
    component: AdminSignIn,
    beforeEnter(to, from, next) {
      const token = getCookie("token");
      if (token) {
        const decodeToken = jwt_decode(token);
        if (!decodeToken || "null" == decodeToken) {
          removeCookie("token");
          return next();
        }

        next({name: "admin-estimates"});
      } else {
        next();
      }
    },
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/500",
    name: "PageError",
    component: PageError,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // if (!getSubdomain()) {
  //   window.location.href = process.env.VUE_APP_BASE_WEB_URL as string;
  //   return;
  // }

  if ("/" === to.path) {
    return next(Paths.ClientEstimate);
  } else if ("/client" === to.path) {
    return next(Paths.ClientEstimate);
  } else if ("/admin" === to.path || "/admin/" === to.path) {
    return next("/admin/signin");
  }

  return next();
});

export default router;
