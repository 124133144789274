export enum ResponseStatusCode {
  "Create_Estimate__Not_Found_Models" = 401,
  "Create_Estimate__Not_Found_Option" = 402,
}

export const ErrorMessageType = {
  unkown_error: "알 수 없는 오류가 발생했습니다.",

  error_already_group: "팀의 소유자는 탈퇴할 수 없습니다, 팀을 위임 또는 제거 후 시도해주세요.",
  error_already_admin: "공유서랍의 PM은 탈퇴할 수 없습니다, 공유서랍 담당을 변경 또는 제거 후 시도해주세요",

  bad_request: "유효하지 않는 요청입니다.",
  bad_admin_not_delete: "관리자는 삭제할 수 없습니다.",
  bad_only_member: "팀원만 가능합니다.",
  bad_password: "비밀번호가 일치하지 않습니다.",
  bad_password_form: "비밀번호 양식이 맞지않습니다.",
  bad_name_form: "이름 양식이 맞지않습니다.",
  bad_email_form: "이메일 양식이 맞지않습니다.",
  bad_certification_email: "이메일로 이동하여 인증을 해주세요.",
  bad_already_exist_email: "같은 이메일이 존재합니다.",
  bad_already_exist_admin: "이미 담당자 권한이 있습니다.",
  bad_already_exist_link: "이미 링크가 존재합니다.",
  bad_already_domain: "도메인이 이미 존재합니다.",

  not_found_data: "요청에 대한 내용을 찾을 수 없습니다.",
  not_found_url: "URL을 찾을 수 없습니다.",
  not_fount_page: "페이지를 찾을 수 없습니다.",
  not_found_user: "사용자를 찾을 수 없습니다.",
  not_found_group: "팀을 찾을 수 없습니다.",
  not_found_project: "공유서랍을 찾을 수 없습니다.",
  not_found_project_folder: "폴더를 찾을 수 없습니다.",
  not_found_member: "팀원을 찾을 수 없습니다.",
  not_found_notice: "공지를 찾을 수 없습니다.",
  not_found_feed: "알림/공지를 찾을 수 없습니다.",
  not_found_email: "이메일을 찾을 수 없습니다.",
  not_found_delete_file: "삭제할 폴더/파일을 찾을 수 없습니다.",

  unauthorized_email: "이메일 인증 후 사용 가능합니다.",
  unauthorized_user: "존재하지 않는 사용자입니다.",

  expire_access_token: "기한이 만료된 토큰입니다.",
  expire_reflesh_token: "리플레시 토큰이 만료되었습니다.",
  expire_public_link: "링크가 만료되었습니다. 소유자에게 문의하세요.",
  expire_drive_aws: "업로드/다운로드 요청시간이 만료되었습니다.",
  expire_auto_code: "인증 만료",

  forbidden_request: "요청에 대한 권한이 없습니다.",
  forbidden_user: "사용자에 대한 권한이 없습니다.",
  forbidden_group: "팀에 대한 권한이 없습니다.",
  forbidden_project: "공유서랍에 대한 권한이 없습니다.",
  forbidden_drive_file: "파일에 대한 권한이 없습니다.",
  forbidden_drive_folder: "폴더에 대한 권한이 없습니다.",
  forbidden_drive_root_create_folder: "홈에는 폴더를 생생할 권한이 없습니다.",
  forbidden_drive_submit_create_folder: "담당자는 제출폴더에 업로드할 수 없습니다.",
  forbidden_drive_root_folder: "홈에는 파일 업로드가 불가능합니다.",
  forbidden_drive_upload: "업로드 권한이 없습니다.",
  forbidden_drive_download: "다운로드 대한 권한이 없습니다.",
  forbidden_drive_permission: "권한이 없습니다.",
  forbidden_drive_download_limit: "일일 다운로드 용량이 없습니다.",
  forbidden_drive_password: "비밀번호가 필요합니다.",
  forbidden_drive_same_password: "비밀번호가 다릅니다.",
  forbidden_feed_register: "공지/이슈 작성은 관리자만 가능합니다.",

  different_domain: "도메인이 일치하지 않습니다.",
  different_email: "이메일이 일치하지 않습니다.",
  different_password: "비밀번호가 일치하지 않습니다.",
};

export function getNetErrorMessage(res: any) {
  // let msg: string = ErrorMessageType[res.data.message];
  // if (!msg) {
  //   if ("production" === process.env.NODE_ENV) {
  //     msg = "서버에서 오류가 발생했습니다.";
  //   } else {
  //     msg = res.data.message;
  //   }
  // }
  // return msg;
}
