import {computed, ref} from "vue";
import {defineStore} from "pinia";
import axios from "axios";
import {toast} from "vue3-toastify";
import {Company, Model3D, Model3DAnalysis, Process} from "../js/interface";
import {CommonStore} from "../store/common-store";
import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {AnalysisStatus, AnalysisStatusKo, AnalysisType} from "@/js/types";
import {createTippySingle} from "../js/utils";
import {EstimatesStore} from "./estimates-store";
import {calculatePart} from "@/js/ban-parking";

/******************************************
 * 하이쓰리디 - 모델 관리 스토어
 ******************************************/
export const ModelStore = defineStore("Model", () => {
  const models = ref(new Array<Model3D>());
  const currentModel = ref({} as Model3D);

  const totalCost = computed(() => {
    let cost = 0;

    for (let i = 0; i < models.value.length; i++) {
      cost += models.value[i].modelCost * models.value[i].modelCount;
    }

    return cost;
  });

  const totalQua = computed(() => {
    let qua = 0;

    for (let i = 0; i < models.value.length; i++) {
      qua += models.value[i].modelCount;
    }

    return qua;
  });

  const getCurrentModel = computed(() => {
    return currentModel;
  });

  function createModel(
    id: string,
    estimateId: number,
    name: string,
    modelKey: string,
    file: any,
    signedUrl: string
  ) {
    const optionsStore = OptionsStore();

    const materials = optionsStore.materials.filter((m) => {
      if (optionsStore.processes[0].id === m.processId) {
        return m;
      }
    });

    const model: Model3D = {
      id: id,
      estimateId: estimateId,
      name: name,
      modelKey: modelKey,
      modelCost: 0,
      modelTotalCost: 0,
      modelCount: 1,
      modelProcessId: optionsStore.processes[0].id,
      modelMaterialId: materials[0].id,
      modelAnalysis: AnalysisStatus.Processing,
      modelPaint: false,
      file: file,
      signedUrl: signedUrl,
      isUploaded: false,
      modelUnit: "mm",
      modelSizeX: 0,
      modelSizeY: 0,
      modelSizeZ: 0,
      modelVolume: 0,
      analysis: [],
    };

    return model;
  }

  function addModel(model: Model3D) {
    models.value.push(model);
  }

  function removeModel(modelId: string) {
    let idx = -1;

    for (let i = 0; i < models.value.length; i++) {
      if (models.value[i].id === modelId) {
        idx = i;
        break;
      }
    }

    if (-1 < idx) {
      const url = `/models/${modelId}`;

      axios
        .delete(url)
        .then((r) => {
          if (!r) {
            toast.error("네트워크가 연결이 안되있거나 서버에 연결할 수 없습니다.", {
              transition: toast.TRANSITIONS.SLIDE,
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          }

          models.value.splice(idx, 1);
          CommonStore().closeGlobalModal();
          EstimateStore().calculateTotalCost();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function clear() {
    models.value = new Array<Model3D>();
  }

  async function setModelCount(modelId: string, count: number) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelCount = count;
        calculateCost(model);
      }
    }
  }

  function setModelTotalCost(modelId: string, cost: number) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelTotalCost = cost;
      }
    }
  }

  function setModelCost(modelId: string, cost: number) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelCost = cost;
        model.modelTotalCost = cost * model.modelCount;
      }
    }
  }

  function setModelPaint(modelId: string, status: boolean) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelPaint = status;
      }
    }
  }

  function setModelProcess(modelId: string, processId: number) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelProcessId = processId;
      }
    }
  }

  function setModelMaterial(modelId: string, materialId: number) {
    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        model.modelMaterialId = materialId;
        calculateCost(model);
      }
    }
  }

  function checkExistByName(name: string) {
    for (let m = 0; m < models.value.length; m++) {
      if (name.normalize() === models.value[m].name.normalize()) {
        return true;
      }
    }
  }

  function setThumbnail(sModel: Model3D) {
    const ext = sModel.name.slice(sModel.name.lastIndexOf(".") + 1).toLowerCase();

    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (sModel.id === model.id) {
        const key = sModel.modelKey;
        // if ("stp" === ext || "STP" === ext) {
        //   key += ".stp.stl";
        // }
        // key += ".png";

        model.thumbnailUrl = `https://s3.ap-northeast-2.amazonaws.com/${process.env.VUE_APP_IMAGE_BUCKET}/${key}`;
      }
    }
  }

  async function reqGetAnalysis(modelId: string, currentProcess: Process) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/models/${modelId}/analysis`, {
          params: {
            processId: currentProcess.id,
          },
        })
        .then((r) => {
          for (let m = 0; m < models.value.length; m++) {
            const model: Model3D = models.value[m];
            if (modelId === model.id) {
              model.analysis = r.data.result.analysis;
              model.analysisRaw = r.data.result.analysisRaw;

              return resolve(true);
            }
          }

          return reject(false);
        });
    });
  }

  async function reqActiveModel(srcModel: Model3D) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/models/${srcModel.id}/active`, {
          materialId: srcModel.modelMaterialId,
          paint: srcModel.modelPaint,
        })
        .then((r) => {
          for (let m = 0; m < models.value.length; m++) {
            const model: Model3D = models.value[m];
            if (srcModel.id === model.id) {
              const rModel = r.data.result.model;

              if (501 === r.data.status) {
                model.modelAnalysis = rModel.modelAnalysis;
                return resolve(true);
              }

              model.modelUnit = rModel.modelUnit;
              model.modelSizeX = rModel.modelSizeX;
              model.modelSizeY = rModel.modelSizeY;
              model.modelSizeZ = rModel.modelSizeZ;
              model.modelVolume = rModel.modelVolume;
              model.modelAnalysis = rModel.modelAnalysis;
              // model.modelCost = rModel.modelCost;
              // model.modelCount = rModel.modelCount;
              // if (rModel.modelCompanyId) model.modelCompanyId = rModel.modelCompanyId;
              model.modelMaterialId = srcModel.modelMaterialId;
              return resolve(true);
            }
          }

          return reject(false);
        });
    });
  }

  function setCurrentModel(model: Model3D) {
    currentModel.value = model;
  }

  function removeAnalysisRaw() {
    for (let m = 0; m < models.value.length; m++) {
      models.value[m]["analysisRaw"] = [];
    }
  }

  async function setActivedModel(modelId: string) {
    const optionsStore = OptionsStore();

    for (let m = 0; m < models.value.length; m++) {
      const model: Model3D = models.value[m];
      if (modelId === model.id) {
        await ModelStore().reqActiveModel(model);
        await ModelStore().reqGetAnalysis(model.id, optionsStore.firstProcess);
        calculateCost(model);
        model.isUploaded = true;
        if (AnalysisStatus.Fail === model.modelAnalysis) {
          return;
        }
        createTippySingle(
          "#btn_" + model.id + "_analysis",
          "분석결과를 확인할 수 있습니다.",
          "bottom"
        );
      }
    }
  }

  // 2024.08.19 계산식 수정 요청 밴파킹
  async function calculateCost(model: Model3D) {
    console.log("calcuate model cost");

    // if (AnalysisStatus.Fail === model.modelAnalysis) {
    //   return;
    // }

    const material = OptionsStore().materials.filter((m) => {
      if (m.id === model.modelMaterialId) {
        return m;
      }
    })[0];
    let companys = OptionsStore().companys;

    // 도색 가능 협력사만 사용
    if (model.modelPaint) {
      companys = companys.filter((val: Company) => {
        if (val.isPaint) {
          return val;
        }
      });
    }

    // 비용 계산 ---------------------------------------------------------------------------
    const costs: Array<{cost: number; company: Company}> = [];
    companys.forEach((company: Company) => {
      if (!company.materialCost) {
        return;
      }

      if (model.modelMaterialId === company.materialId) {
        let cost = 0;
        // abs like, abs clear
        if (
          material.name.toLocaleLowerCase().includes("abs like") ||
          material.name.toLocaleLowerCase().includes("abs clear")
        ) {
          const a =
            model.modelSizeX *
            model.modelSizeY *
            model.modelSizeZ *
            Number.parseFloat(company.costRate);
          const b = Number.parseInt(company.useCost) / model.modelCount;
          cost = a + b;
        }
        // pla
        else if (material.name.toLocaleLowerCase().includes("pla")) {
          const a = model.modelVolume * Number.parseFloat(company.costRate);
          const b = Number.parseInt(company.useCost) / model.modelCount;
          cost = a + b;
        }
        // pa12
        else if (
          material.name.toLocaleLowerCase().includes("pa12") ||
          material.name.toLocaleLowerCase().includes("pa 12")
        ) {
          const finalHeight = calculatePart(
            [model],
            Number.parseInt(company.buildX),
            Number.parseInt(company.buildZ),
            Number.parseInt(company.buildY)
          );

          const a = model.modelVolume * Number.parseFloat(company.costRate);
          const b = (Number.parseInt(company.useCost) * finalHeight) / model.modelCount;
          cost = a + b;
        } else {
          cost = model.modelVolume * Number.parseFloat(company.costRate);
        }

        // 최소비용 / 1원 단위 삭제
        if (model.modelMaterialId) {
          cost = cost < Number.parseInt(company.minCost) ? Number.parseInt(company.minCost) : cost;
        }
        if (0 < cost) {
          costs.push({cost: Math.ceil(cost / 100) * 100, company: company});
        }
      }
    });

    costs.sort((a, b) => {
      if (a.cost > b.cost) {
        return 1;
      }
      if (a.cost < b.cost) {
        return -1;
      } else {
        return 0;
      }
    });
    // 비용 계산 ---------------------------------------------------------------------------

    // 빌드 사이즈 검사 ---------------------------------------------------------------------------
    const finalCompanys: {cost: number; company: Company}[] = [];
    costs.forEach((c) => {
      let status = false;

      const buildX = Number.parseInt(c.company.buildX);
      const buildY = Number.parseInt(c.company.buildY);
      const buildZ = Number.parseInt(c.company.buildZ);

      if (model.modelSizeX < buildX && model.modelSizeY < buildY && model.modelSizeZ < buildZ) {
        status = true;
      }
      if (model.modelSizeX < buildZ && model.modelSizeY < buildX && model.modelSizeZ < buildY) {
        status = true;
      }
      if (model.modelSizeX < buildY && model.modelSizeY < buildX && model.modelSizeX < buildZ) {
        status = true;
      }

      if (model.modelSizeX < buildX && model.modelSizeY < buildZ && model.modelSizeX < buildY) {
        status = true;
      }
      if (model.modelSizeX < buildZ && model.modelSizeY < buildY && model.modelSizeZ < buildX) {
        status = true;
      }
      if (model.modelSizeX < buildY && model.modelSizeY < buildX && model.modelSizeZ < buildZ) {
        status = true;
      }

      if (status) {
        finalCompanys.push(c);
      }
    });

    let isUnableSize = false;
    if (!finalCompanys.length) {
      isUnableSize = true;
      finalCompanys.push({cost: 0, company: companys[0]});
    }

    model.modelCost = finalCompanys[0].cost;
    model.modelCompanyId = finalCompanys[0].company.id;

    EstimateStore().calculateTotalCost();
  }

  return {
    models,
    currentModel,
    totalCost,
    totalQua,
    getCurrentModel,
    createModel,
    addModel,
    removeModel,
    clear,
    setModelCount,
    setModelTotalCost,
    setModelCost,
    setModelProcess,
    setModelMaterial,
    setModelPaint,
    setCurrentModel,
    setThumbnail,
    checkExistByName,
    reqGetAnalysis,
    reqActiveModel,
    removeAnalysisRaw,
    setActivedModel,
    calculateCost,
  };
});
