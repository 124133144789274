import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import axios from "./js/axios";

import KProgress from "k-progress-v3";
import Vue3Toastify, { toast, type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";
import PaginationPlugin from "vue3-basic-pagination"; // import component
import "vue3-basic-pagination/styles"; // register default styles

const app = createApp(App);
app.config.globalProperties.axios = axios;
const pinia = createPinia();
app.use(pinia);
app.use(ContextMenu);
app.use(PaginationPlugin);
app.component("k-progress", KProgress);
app.component("VueDatepicker", VueDatepickerUi);
app
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 3000,
    icon: false,
    toastClassName: "toast-class",
    bodyClassName: "toast-class",
    style: { transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER },
  } as ToastContainerOptions)
  .mount("#app");
