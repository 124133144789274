import tippy, {createSingleton} from "tippy.js";

export function randomByNumber(length: number) {
  let value = "";
  for (let i = 0; i < length; i++) {
    const num = Math.floor(Math.random() * 9 + 0);
    if (0 == num) {
      i--;
    } else {
      value = value.concat(num.toString());
    }
  }

  return value;
}

export function numberToformatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function numberToComma(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeComma(src: string) {
  return src.replace(/,/g, "");
}

export function padZero(val: number) {
  let result = Number(val).toString();
  if (10 > val) {
    result = "0" + val;
  }

  return result;
}

export function dateConvertYMDHM_KR(time: number) {
  const t: number = time;
  const a = new Date(t.valueOf() * 1000);
  const year = a.getFullYear();
  const month = a.getMonth();
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const output = year + "년 " + padZero(month + 1) + "월 " + padZero(date) + "일";
  return output;
}

export function dateConvertYMDHM(time: number) {
  const t: number = time;
  const a = new Date(t.valueOf() * 1000);
  const year = a.getFullYear();
  const month = a.getMonth();
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const output =
    year +
    "." +
    padZero(month + 1) +
    "." +
    padZero(date) +
    " " +
    padZero(hour) +
    ":" +
    padZero(min);
  return output;
}

export function validateName(name: string) {
  const pattern1 = /[~!@#$%^&*+|<>?:{}]/;

  if (pattern1.test(name)) {
    return "특수문자는 포함할 수 없습니다.";
  } else if (2 > name.length) {
    return "이름은 2자 이상이어야 합니다.";
  } else {
    return null;
  }
}

export function validateEmail(email: string) {
  if (!email.trim().length) {
    return "";
  }

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const result = email.trim().match(mailformat);
  if (!result) {
    return "이메일 형식이 맞지 않습니다.";
  }

  return "";
}

export function validatePhone(phone: string) {
  const val = phone
    .replace(/[^0-9]/g, "")
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3")
    .replace("--", "-");

  const pattern1 = /^01([0|1|2|3|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const pattern2 = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/;
  const result1 = !pattern1.test(val) && !pattern2.test(val);
  const result2 = 1 > val.length && 13 < val.length;

  if (result1 || result2) {
    return "전화번호 양식과 다릅니다.";
  } else {
    return "";
  }
}

export function setCookie(name: string, value: any, options = {} as any) {
  if ("production" !== process.env.NODE_ENV) {
    console.log("set coookie - name: " + name + " / value: " + value);
  }

  options = {
    path: "/",
    domain: `.${process.env.VUE_APP_BASE_WEB_URL_COOKIE}`,
    // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;

  // SameSite Secure https에서 다른 쿠키 못 가져와서 오류
  // if ("production" === process.env.NODE_ENV) {
  //   document.cookie = updatedCookie + "SameSite=None; Secure;";
  // } else {
  //   document.cookie = updatedCookie;
  // }
}
export function getCookie(key: string) {
  let value: null | string = sessionStorage.getItem(key);

  if (!value) {
    value = localStorage.getItem(key);
    if (!value) {
      let matchs: null | RegExpMatchArray = document.cookie.match(
        "(^|;) ?" + key + "=([^;]*)(;|$)"
      );
      if (matchs) {
        return decodeURIComponent(matchs[2]);
      } else {
        matchs = null;
      }
    }
  }

  return value;
}
export function removeCookie(name: string) {
  setCookie(name, "", {
    domain: "." + process.env.VUE_APP_BASE_WEB_URL_COOKIE,
    "max-age": -1,
  });
}

export function createTippySingle(button: any, content: any, placement: any) {
  if (isMobile()) {
    return;
  }

  const values: any = {content: content, animation: "shift-away", delay: 5000};
  if (placement) {
    values["placement"] = placement;
  }

  const tippyInstances: any = tippy(button, values);
  try {
    if (tippyInstances.length) {
      tippyInstances[0].show();
    }
  } catch (e) {
    tippyInstances.show();
  }

  setTimeout(() => {
    try {
      if (tippyInstances.length) {
        tippyInstances[tippyInstances.length - 1].destroy();
      }
    } catch (e) {
      tippyInstances.destroy();
    }
  }, 5000);
}

export function isMobile() {
  const UserAgent = navigator.userAgent;

  if (
    UserAgent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    UserAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
}

export function getSubdomain() {
  const host = window.location.host;
  const parts = host.split(".");
  const domainLength = 3;

  if (parts.length <= domainLength - 1) {
    return null;
  } else {
    return parts[0];
  }
}
