import {Material, Process, Company} from "@/js/interface";
import axios from "axios";
import {defineStore} from "pinia";
import {toast} from "vue3-toastify";
import {LoaderButtonStore} from "./loader-button-store";

import {numberToComma, randomByNumber, removeComma} from "../js/utils";

export const CompanyStore = defineStore("company", {
  state: () => ({
    processList: [] as Process[],
  }),
  getters: {},
  actions: {
    addProcess(name: string) {
      this.processList.push({
        id: Number.parseInt(randomByNumber(5)),
        name: name,
        rowIdx: this.processList.length,
        thicknessWarn: 0.3,
        thicknessCaution: 0.5,
        gapWarn: 0.3,
        gapCaution: 0.5,
        matarials: new Array<Material>(),
      });
    },
    removeProcess(id: number) {
      let idx = -1;

      for (let i = 0; i < this.processList.length; i++) {
        if (this.processList[i].id === id) {
          idx = i;
          break;
        }
      }

      this.processList.splice(idx, 1);

      for (let i = 0; i < this.processList.length; i++) {
        this.processList[i].rowIdx = i;
      }
    },
    upProcess(idx: number) {
      if (0 > idx - 1) {
        return;
      }

      const src = this.processList[idx];
      const dsc = this.processList[idx - 1];
      const srcIdx = src.rowIdx;
      src.rowIdx = dsc.rowIdx;
      dsc.rowIdx = srcIdx;

      this.processList.sort((a, b) => {
        return a.rowIdx - b.rowIdx;
      });
    },
    downProcess(idx: number) {
      if (this.processList.length === idx + 1) {
        return;
      }

      const src = this.processList[idx];
      const dsc = this.processList[idx + 1];
      const srcIdx = src.rowIdx;
      src.rowIdx = dsc.rowIdx;
      dsc.rowIdx = srcIdx;

      this.processList.sort((a, b) => {
        return a.rowIdx - b.rowIdx;
      });
    },
    updateProcess(values: any) {
      for (let i = 0; i < this.processList.length; i++) {
        if (this.processList[i].id === values.id) {
          this.processList[i].name = values.name;
          this.processList[i].thicknessWarn = values.thickWarn;
          this.processList[i].thicknessCaution = values.thickCaution;
          this.processList[i].gapWarn = values.gapWarn;
          this.processList[i].gapCaution = values.gapCaution;
          break;
        }
      }
    },

    addMaterial(processId: number, name: string) {
      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const matarial: Material = {
            id: Number.parseInt(randomByNumber(5)),
            processId: processId,
            name: name,
            rowIdx: this.processList[p].matarials.length,
            companys: new Array<Company>(),

            companyName: "",
          };

          this.processList[p].matarials.push(matarial);

          break;
        }
      }
    },
    removeMaterial(processId: number, materialId: number) {
      let idx = -1;

      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials: Material[] = this.processList[p].matarials;

          for (let m = 0; m < materials.length; m++) {
            if (materials[m].id === materialId) {
              idx = m;
              break;
            }
          }

          if (-1 !== idx) {
            materials.splice(idx, 1);
          }

          for (let m = 0; m < materials.length; m++) {
            materials[m].rowIdx = m;
          }
        }
      }
    },
    upMaterial(processId: number, idx: number) {
      if (0 > idx - 1) {
        return;
      }

      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials: Material[] = this.processList[p].matarials;

          const src = materials[idx];
          const dsc = materials[idx - 1];
          const srcIdx = src.rowIdx;
          src.rowIdx = dsc.rowIdx;
          dsc.rowIdx = srcIdx;

          materials.sort((a, b) => {
            return a.rowIdx - b.rowIdx;
          });
        }
      }
    },
    downMaterial(processId: number, idx: number) {
      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials: Material[] = this.processList[p].matarials;

          if (materials.length === idx + 1) {
            return;
          }

          const src = materials[idx];
          const dsc = materials[idx + 1];
          const srcIdx = src.rowIdx;
          src.rowIdx = dsc.rowIdx;
          dsc.rowIdx = srcIdx;

          materials.sort((a, b) => {
            return a.rowIdx - b.rowIdx;
          });
        }
      }
    },

    addCompany(processId: number, materialId: number, companyName: string) {
      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials = this.processList[p].matarials;
          for (let m = 0; m < materials.length; m++) {
            if (materials[m].id === materialId) {
              const company: Company = {
                id: Number.parseInt(randomByNumber(5)),
                materialId: materialId,
                rowIdx: materials[m].companys.length,
                name: companyName,
                printer: "",
                buildX: "0",
                buildY: "0",
                buildZ: "0",
                isPostProcess: false,
                isPaint: false,
                costRate: "0",
                minCost: "0",
                useCost: "0",
                materialCost: "0",
              };

              materials[m].companys.push(company);

              break;
            }
          }
        }
      }
    },
    removeCompany(processId: number, materialId: number, companyId: number) {
      let idx = -1;

      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials = this.processList[p].matarials;
          for (let m = 0; m < materials.length; m++) {
            if (materials[m].id === materialId) {
              const companys = materials[m].companys;
              for (let c = 0; c < companys.length; c++) {
                if (companys[c].id === companyId) {
                  idx = c;
                  break;
                }
              }

              if (-1 !== idx) {
                companys.splice(idx, 1);
              }

              for (let c = 0; c < companys.length; c++) {
                companys[c].rowIdx = c;
              }
            }
          }
        }
      }
    },
    upCompany(processId: number, materialId: number, idx: number) {
      if (0 > idx - 1) {
        return;
      }

      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials = this.processList[p].matarials;
          for (let m = 0; m < materials.length; m++) {
            if (materials[m].id === materialId) {
              const companys = materials[m].companys;

              const src = companys[idx];
              const dsc = companys[idx - 1];
              const srcIdx = src.rowIdx;
              src.rowIdx = dsc.rowIdx;
              dsc.rowIdx = srcIdx;

              companys.sort((a, b) => {
                return a.rowIdx - b.rowIdx;
              });
            }
          }
        }
      }
    },
    downCompany(processId: number, materialId: number, idx: number) {
      for (let p = 0; p < this.processList.length; p++) {
        if (this.processList[p].id === processId) {
          const materials = this.processList[p].matarials;
          for (let m = 0; m < materials.length; m++) {
            if (materials[m].id === materialId) {
              const companys = materials[m].companys;

              if (companys.length === idx + 1) {
                return;
              }

              const src = companys[idx];
              const dsc = companys[idx + 1];
              const srcIdx = src.rowIdx;
              src.rowIdx = dsc.rowIdx;
              dsc.rowIdx = srcIdx;

              companys.sort((a, b) => {
                return a.rowIdx - b.rowIdx;
              });
            }
          }
        }
      }
    },

    reqProcesses() {
      this.processList = [] as Process[];

      axios
        .get("/admin/processes")
        .then((r) => {
          const processes = r.data.processes;
          const materials = r.data.materials;
          const companys = r.data.companys;

          for (let p = 0; p < processes.length; p++) {
            const process: Process = {
              id: processes[p].id,
              rowIdx: processes[p].rowIdx,
              name: processes[p].name,
              thicknessWarn: processes[p].thicknessWarn,
              thicknessCaution: processes[p].thicknessCaution,
              gapWarn: processes[p].gapWarn,
              gapCaution: processes[p].gapCaution,
              matarials: new Array<Material>(),
            };

            for (let m = 0; m < materials.length; m++) {
              const material: Material = {
                id: materials[m].id,
                processId: materials[m].processId,
                rowIdx: materials[m].rowIdx,
                name: materials[m].name,
                companys: new Array<Company>(),
                companyName: "",
              };

              for (let c = 0; c < companys.length; c++) {
                const company: Company = {
                  id: companys[c].id,
                  processId: material.processId,
                  materialId: companys[c].materialId,
                  rowIdx: companys[c].rowIdx,
                  name: companys[c].name,
                  printer: companys[c].printer,
                  buildX: "" + companys[c].buildX,
                  buildY: "" + companys[c].buildY,
                  buildZ: "" + companys[c].buildZ,
                  isPaint: companys[c].isPaint,
                  isPostProcess: companys[c].isPostProcess,
                  costRate: companys[c].costRate,
                  minCost: numberToComma(companys[c].minCost),
                  useCost: numberToComma(companys[c].useCost),
                  materialCost: numberToComma(companys[c].materialCost),
                };

                if (material.id === company.materialId) {
                  material.companys.push(company);
                }
              }

              if (process.id === material.processId) {
                process.matarials.push(material);
              }
            }

            this.processList.push(process);
          }
        })
        .catch((e) => {
          toast.error("오류가 발생했습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
        });
    },
    submit() {
      const loaderButtonStore = LoaderButtonStore();

      let isFail = false;

      for (let p = 0; p < this.processList.length; p++) {
        const process = this.processList[p];
        if (!process.name.length) {
          isFail = true;
          toast.error("공정 이름이 잘 못 되었습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
          break;
        }

        for (let m = 0; m < process.matarials.length; m++) {
          const material = process.matarials[m];
          if (!material.name.length) {
            isFail = true;
            toast.error("재료 이름이 잘 못 되었습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
            break;
          }

          for (let c = 0; c < material.companys.length; c++) {
            const company = material.companys[c];
            if (!company.name.length) {
              isFail = true;
              toast.error("협력사 이름이 잘 못 되었습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
              break;
            }

            // company.minCost = removeComma(company.minCost);
            // company.useCost = removeComma(company.useCost);
            // company.materialCost = removeComma(company.materialCost);
          }
        }
      }

      if (isFail) {
        loaderButtonStore.stop();
        return;
      }

      axios
        .post("/admin/processes", {
          processes: this.processList,
        })
        .then((r) => {
          loaderButtonStore.stop();
          toast.success("저장 완료.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
        })
        .catch((e) => {
          loaderButtonStore.stop();
        });
    },
  },
});
