
/******************************************
 * 하이쓰리디 - 관리자 공정,협력사 설정 화면
 ******************************************/

import {ref, defineComponent, onMounted} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";

import {CompanyStore} from "../../store/company-store";

import {numberToComma, removeComma} from "../../js/utils";

import LoaderButton from "../../components/LoaderButton.vue";
import ProcessItem from "./../components/process-item.vue";

export default defineComponent({
  name: "AdminSetting",
  components: {LoaderButton, ProcessItem},
  props: {
    msg: String,
  },
  setup() {
    const companyStore = CompanyStore();
    const {processList} = storeToRefs(companyStore);
    companyStore.processList;

    let processName = ref("");

    onMounted(() => {
      companyStore.reqProcesses();
    });

    const onAddProcess = () => {
      companyStore.addProcess(processName.value);
      processName.value = "";
    };

    const onSubmit = () => {
      companyStore.submit();
    };

    const numberComma = (src: string) => {
      if (src.length) {
        return numberToComma(Number.parseInt(removeComma(src)));
      }

      return "";
    };

    return {
      processList,
      processName,

      onSubmit,

      onAddProcess,

      numberComma,
    };
  },
});
