
/******************************************
 * 하이쓰리디 - 관리자 기본 설정 화면
 ******************************************/

import {ref, computed, defineComponent, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import {storeToRefs} from "pinia";

import {OptionsStore} from "../../store/options-store";
import {EstimateStore} from "../../store/estimate-store";
import {ModelStore} from "../../store/model-store";
import {CommonStore} from "../../store/common-store";

import {changeInputFile} from "../../js/upload";
import {numberToComma, removeComma, dateConvertYMDHM_KR, validateEmail, validatePhone} from "../../js/utils";
import {Options} from "../../js/types";
import axios from "../../js/axios";

import InputSearch from "../../components/InputSearch.vue";
import Thumbnail from "../../components/Thumbnail.vue";
import LoaderButton from "../../components/LoaderButton.vue";

export default defineComponent({
  name: "AdminSettingDefault",
  components: {LoaderButton},
  props: {
    msg: String,
  },
  setup() {
    const optionsStore = OptionsStore();
    const {
      optionEmail,
      optionDefaultPostProcessCost,
      optionPostProcessCost,
      optionDefaultPaintCost,
      optionPaintCost,
      optionShippingCost,
      optionCautionPaint,
      optionBuildSizeX,
      optionBuildSizeY,
      optionBuildSizeZ,
      optionVolumeMultiply,
      optionMinVolume,
      optionModelMaxSize,
    } = storeToRefs(optionsStore);

    let email = ref("");
    let errMsgEmail = ref("");
    let defaultPostProcessing = ref("");
    let variablePostProcessing = ref("");
    let defaultPainting = ref("");
    let variablePainting = ref("");
    let shipping = ref("");
    let cautionPaint = ref("");

    onMounted(() => {
      optionsStore.reqGetOptions();
    });

    const onSubmit = () => {
      console.log("onSubmit");

      optionsStore.reqSubmit();
    };

    const onValidateEmail = (e: any) => {
      errMsgEmail.value = validateEmail(e.target.value);
    };

    const onInputShipping = () => {
      if (0 === optionShippingCost.value.length) {
        optionShippingCost.value = "0";
      }
    };

    const numberComma = (src: string) => {
      if (src.length) {
        src = src.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        if (src.length) {
          return numberToComma(Number.parseInt(removeComma(src)));
        }
      }

      return "";
    };

    const onInputNumber = () => {
      optionBuildSizeX.value = removeComma(optionBuildSizeX.value);
      optionBuildSizeY.value = removeComma(optionBuildSizeY.value);
      optionBuildSizeZ.value = removeComma(optionBuildSizeZ.value);
      optionMinVolume.value = removeComma(optionMinVolume.value);
      optionModelMaxSize.value = removeComma(optionModelMaxSize.value);

      const regExp = /^\d{0,10}$/;
      if (!regExp.test(optionBuildSizeX.value)) {
        optionBuildSizeX.value = optionBuildSizeX.value.substring(0, optionBuildSizeX.value.length - 1);
      }
      if (!regExp.test(optionBuildSizeY.value)) {
        optionBuildSizeY.value = optionBuildSizeY.value.substring(0, optionBuildSizeY.value.length - 1);
      }
      if (!regExp.test(optionBuildSizeZ.value)) {
        optionBuildSizeZ.value = optionBuildSizeZ.value.substring(0, optionBuildSizeZ.value.length - 1);
      }
      if (!regExp.test(optionMinVolume.value)) {
        optionMinVolume.value = optionMinVolume.value.substring(0, optionMinVolume.value.length - 1);
      }
      if (!regExp.test(optionModelMaxSize.value)) {
        optionModelMaxSize.value = optionModelMaxSize.value.substring(0, optionModelMaxSize.value.length - 1);
      }

      optionBuildSizeX.value = numberComma(optionBuildSizeX.value.replace(/(^0+)/, ""));
      optionBuildSizeY.value = numberComma(optionBuildSizeY.value.replace(/(^0+)/, ""));
      optionBuildSizeZ.value = numberComma(optionBuildSizeZ.value.replace(/(^0+)/, ""));
      optionMinVolume.value = numberComma(optionMinVolume.value.replace(/(^0+)/, ""));
      optionModelMaxSize.value = numberComma(optionModelMaxSize.value.replace(/(^0+)/, ""));
    };
    const onInputBlurNumber = () => {
      if (0 === optionBuildSizeX.value.length) {
        optionBuildSizeX.value = "0";
      }
      if (0 === optionBuildSizeY.value.length) {
        optionBuildSizeY.value = "0";
      }
      if (0 === optionBuildSizeZ.value.length) {
        optionBuildSizeZ.value = "0";
      }
      if (0 === optionModelMaxSize.value.length) {
        optionModelMaxSize.value = "0";
      }
    };

    const onInputRatio = () => {
      optionVolumeMultiply.value = optionVolumeMultiply.value.replace(/[^0-9.]/g, "");
    };
    const onInputBlurRatio = () => {
      if ("number" === typeof optionVolumeMultiply.value) {
        return;
      }

      if (0 < optionVolumeMultiply.value.length) {
        if ("." === optionVolumeMultiply.value[0]) {
          optionVolumeMultiply.value = "0" + optionVolumeMultiply.value;
        }
      }
    };

    return {
      optionEmail,
      optionDefaultPostProcessCost,
      optionPostProcessCost,
      optionDefaultPaintCost,
      optionPaintCost,
      optionShippingCost,
      optionCautionPaint,
      optionBuildSizeX,
      optionBuildSizeY,
      optionBuildSizeZ,
      optionVolumeMultiply,
      optionMinVolume,
      optionModelMaxSize,

      email,
      defaultPostProcessing,
      variablePostProcessing,
      defaultPainting,
      variablePainting,
      shipping,
      cautionPaint,

      errMsgEmail,

      onSubmit,

      onValidateEmail,
      onInputShipping,
      numberComma,
      onInputNumber,
      onInputBlurNumber,
      onInputRatio,
      onInputBlurRatio,
    };
  },
});
