
import { ref, computed, defineComponent, onMounted } from "vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "PageNotFound",
  components: {},
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
});
