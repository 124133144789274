
/******************************************
 * 하이쓰리디 - 클라이언트 견적서 화면
 ******************************************/

import {ref, computed, defineComponent, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import ClientModel from "./client-model.vue";
import {useRouter, useRoute} from "vue-router";

import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {ModelStore} from "../store/model-store";
import {CommonStore} from "../store/common-store";

import {changeInputFile, preProcessingFileUpload} from "../js/upload";
import {AnalysisStatus, Modals} from "../js/types";
import {numberToComma, dateConvertYMDHM_KR, removeComma} from "../js/utils";

import ModalAnalysis from "./modals/modal-analysis.vue";
import ModalClientSubmit from "./modals/modal-client-submit.vue";
import {Model3D} from "../js/interface";

import {socket, socketJoin} from "../js/socket";

export default defineComponent({
  name: "ClientEstimate",
  components: {
    ClientModel,
    ModalAnalysis,
  },
  props: {
    msg: String,
  },
  setup() {
    const commonStore = CommonStore();
    const {modalResult} = storeToRefs(commonStore);
    const optionsStore = OptionsStore();
    const {optionShippingCost, isEnabled} = storeToRefs(optionsStore);
    const estimateStore = EstimateStore();
    const {estimate, isEstimateSubmit} = storeToRefs(estimateStore);
    const {models, currentModel, totalQua} = storeToRefs(ModelStore());
    const inputFileData = ref();

    const router = useRouter();
    const route = useRoute();

    const unit = ref("mm");
    const isDraging = ref(false);
    const isVisibleViewer = ref(false);

    const todayDate = computed(() => {
      return dateConvertYMDHM_KR(Date.now() / 1000);
    });
    const shippingCost = computed(() => {
      return numberToComma(models.value.length ? estimate.value.shippingCost : 0);
    });
    const discountCost = computed(() => {
      return numberToComma(models.value.length ? estimate.value.discountCost * -1 : 0);
    });
    const totalCost = computed(() => {
      const vat = 0;
      return models.value.length ? numberToComma(estimate.value.totalCost + vat) : 0;
    });
    const totalModelQuantiy = computed(() => {
      return totalQua;
    });

    watch(optionShippingCost, (newV: string, oldV: string) => {
      estimate.value.shippingCost = Number.parseInt(removeComma(optionShippingCost.value));
    });
    watch(currentModel, (newV: Model3D, oldV: Model3D) => {
      if (newV.id) {
        isVisibleViewer.value = true;
      } else {
        isVisibleViewer.value = false;
      }
    });
    watch(modalResult, (newV, oldV) => {
      switch (newV.modalName) {
        case Modals.SubmiEstimate:
          window.location.href = process.env.VUE_APP_BASE_WEB_URL as string;
          break;
      }
    });

    onMounted(() => {
      if (estimate.value.id) {
        return;
      }
      estimateStore.createEstimate();
      optionsStore.reqGetOptions();

      socketJoin();
    });

    /************************************************************
     * Event
     ***********************************************************/
    const onUnit = (srcUnit: string) => {
      unit.value = srcUnit;
      commonStore.setUnit(unit.value);
    };

    const inputFileFocus = () => {
      if (inputFileData.value) {
        inputFileData.value.click();
      }
    };

    const onTerm = () => {
      CommonStore().showTermModal();
    };

    /************************************************************
     * Drag
     ***********************************************************/
    const onDrop = (e: DragEvent) => {
      isDraging.value = false;

      e.preventDefault();

      if (e.dataTransfer) {
        let droppedFiles = e.dataTransfer.files;
        if (!droppedFiles) return;
        if (!isEstimateSubmit.value) return;

        preProcessingFileUpload(droppedFiles);
      }
    };

    const onDragEnter = (e: DragEvent) => {
      isDraging.value = true;
    };

    const onDragLeave = (e: DragEvent) => {
      isDraging.value = false;
    };

    const onDragEnd = () => {
      isDraging.value = false;
    };

    /************************************************************
     * Submit
     ***********************************************************/
    const onSubmit = () => {
      CommonStore().showModal(ModalClientSubmit, null);
    };
    const onGoAR = () => {
      const arUrl = (process.env.VUE_APP_API_SERVER_URL as string) + "/ar";
      window.open(arUrl, "_blank");
    };

    return {
      currentModel,
      isVisibleViewer,

      AnalysisStatus,

      inputFileData,
      estimate,
      isEstimateSubmit,
      models,
      shippingCost,
      discountCost,
      isEnabled,
      totalCost,
      todayDate,
      totalModelQuantiy,

      changeInputFile,
      numberToComma,
      inputFileFocus,

      unit,
      onUnit,
      onTerm,

      isDraging,
      onDrop,
      onDragEnter,
      onDragLeave,
      onDragEnd,

      onSubmit,
      onGoAR,
    };
  },
});
