
import {ref, computed, defineComponent, onMounted, watch} from "vue";
import {useRouter, useRoute} from "vue-router";
import {storeToRefs} from "pinia";

import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {ModelStore} from "../store/model-store";
import {CommonStore} from "../store/common-store";

import {numberToComma, dateConvertYMDHM_KR, removeComma} from "../js/utils";

export default defineComponent({
  name: "ClientEstimate",
  components: {},
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const commonStore = CommonStore();
    const {modalResult} = storeToRefs(commonStore);
    const optionsStore = OptionsStore();
    const {optionShippingCost, isEnabled} = storeToRefs(optionsStore);
    const estimateStore = EstimateStore();
    const {estimate, isEstimateSubmit} = storeToRefs(estimateStore);
    const {models, currentModel} = storeToRefs(ModelStore());

    const shippingCost = computed(() => {
      return numberToComma(models.value.length ? estimate.value.shippingCost : 0);
    });
    const vat = computed(() => {
      return numberToComma(
        estimate.value.totalCost ? Math.floor(estimate.value.totalCost * 0.1) : 0
      );
    });
    const discountCost = computed(() => {
      return estimate.value.discountCost ? numberToComma(estimate.value.discountCost * -1) : 0;
    });
    const totalCost = computed(() => {
      const vat = Math.floor(estimate.value.totalCost * 0.1);
      return models.value.length ? numberToComma(estimate.value.totalCost + vat) : 0;
    });

    const onPrint = () => {
      router.push({
        name: "estimate-print",
      });
    };

    const onBack = () => {
      window.location.href = process.env.VUE_APP_BASE_WEB_URL as string;

      // router.push({
      //   name: "client-estimate",
      // });
    };

    return {
      estimate,

      shippingCost,
      vat,
      discountCost,
      totalCost,
      numberToComma,

      onPrint,
      onBack,
    };
  },
});
