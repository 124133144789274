
import { ref, defineComponent } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { setCookie } from "../js/utils";

export default defineComponent({
  name: "AdminTestAnalysis",
  components: {},
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();

    const modelId = ref("");
    const url = ref("");
    const extension = ref("");
    const unit = ref("");
    const minVolume = ref("");
    const maxLength = ref("");
    const volumeMultiply = ref("");
    const maxBuildSizeX = ref("");
    const maxBuildSizeY = ref("");
    const maxBuildSizeZ = ref("");
    const thicknessWarn = ref("");
    const thicknessCaution = ref("");
    const gapWarn = ref("");
    const gapCaution = ref("");

    const onSubmit = () => {
      return;
    };

    return { modelId, url, extension, unit, minVolume, maxLength, volumeMultiply, maxBuildSizeX, maxBuildSizeY, maxBuildSizeZ, thicknessWarn, thicknessCaution, gapWarn, gapCaution, onSubmit };
  },
});
