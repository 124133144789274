import * as THREE from "three";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {cfgCommon, WarnCautionColor} from "../views/components/3d/ctxConfig"; //  초기 설정 정보

import {Model3D} from "./interface";
import {getNewCamPosToMesh, moveSceneToBottom, setMesh2OriginUpper, clearScene} from "@/views/components/3d/ctxUtils";
import axios from "axios";

import {ModelStore} from "../store/model-store";

// S3 버킷 이름 및 파일 이름 설정
const width = 260;
const height = 260;

/******************************************
 * 하이쓰리디 - stl파일의 썸네일 생성
 ******************************************/
export async function genThumbnail(model: Model3D, file: any) {
  const reader = new FileReader();
  try {
    reader.onload = async function (event) {
      if (!event.target) {
        return;
      }

      const data = event.target.result;
      if (!data) {
        return;
      }

      const loader = new STLLoader();
      try {
        const geometry = loader.parse(data);
        const scene = new THREE.Scene();
        const renderer = new THREE.WebGLRenderer({antialias: true, preserveDrawingBuffer: true});
        renderer.setSize(width, height);
        renderer.setClearColor(0xffffff, 0);
        const camera = new THREE.PerspectiveCamera(cfgCommon.camera.cameraFov, width / height, 0.1, 3000);
        const groupModels = new THREE.Group();

        const faceMaterial = new THREE.MeshLambertMaterial({
          color: new THREE.Color("#eeeeee"),
          side: THREE.FrontSide,
          transparent: false,
        });
        const mesh = new THREE.Mesh(geometry, faceMaterial);
        mesh.scale.set(1, 1, 1);
        mesh.visible = true;
        groupModels.add(mesh);
        scene.add(groupModels);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight.position.set(1000, -1000, 1000);
        directionalLight.name = "directionalLight";
        scene.add(directionalLight);
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight2.position.set(1000, 1000, 1000);
        directionalLight2.name = "directionalLight";
        scene.add(directionalLight2);
        const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight3.position.set(-1000, 0, 1000);
        directionalLight3.name = "directionalLight";
        scene.add(directionalLight3);

        // 별도 함수를 사용하여 바닥면 기준으로 이동 시킴 (setMesh2OriginUpper @CtxUtils.ts 를 사용)
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const bbox_size = boundingBox.getSize(new THREE.Vector3());
        const bbox_center = boundingBox.getCenter(new THREE.Vector3());
        mesh.position.sub(bbox_center);
        mesh.position.z = -boundingBox.min.z;

        // Camera Set ------------------------------------
        const cameraPosition = getNewCamPosToMesh(groupModels, cfgCommon.camera.pos);
        camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        camera.lookAt(new THREE.Vector3(0, bbox_size.z / 2, 0));

        const moveDistance = bbox_center.z * cfgCommon.scene.moveDistance;
        moveSceneToBottom(scene, camera, moveDistance);

        scene.rotation.x = -Math.PI / 2;
        renderer.render(scene, camera);

        const screenshotDataURL = renderer.domElement.toDataURL();
        const screenshotBlob = dataURLtoBlob(screenshotDataURL);

        clearScene(scene);

        const resSignedUrl = await axios.post(`/models/${model.id}/gen-thumbnail`);
        const axiosIns = axios.create();
        axiosIns
          .put(resSignedUrl.data.result.signedUrl as string, screenshotBlob)
          .then(async () => {
            ModelStore().setThumbnail(model);
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (error) {
        console.error("CTX: E001:try..catch err: An error rendering the model:", error);
      }
    };
    reader.readAsArrayBuffer(file);
  } catch (error) {
    console.error("CTX: E001:try..catch err: An error load the model:", error);
  }
}

/******************************************
 * 하이쓰리디 - stp파일의 썸네일 생성
 ******************************************/
export async function genStpThumbnail(model: Model3D, path: any) {
  const loader = new STLLoader();

  try {
    loader.load(path, async (geometry) => {
      try {
        const scene = new THREE.Scene();
        const renderer = new THREE.WebGLRenderer({antialias: true, preserveDrawingBuffer: true});
        renderer.setSize(width, height);
        renderer.setClearColor(0xffffff, 0);
        const camera = new THREE.PerspectiveCamera(cfgCommon.camera.cameraFov, width / height, 0.1, 3000);
        const groupModels = new THREE.Group();

        const faceMaterial = new THREE.MeshLambertMaterial({
          color: new THREE.Color("#eeeeee"),
          side: THREE.FrontSide,
          transparent: false,
        });
        const mesh = new THREE.Mesh(geometry, faceMaterial);
        mesh.scale.set(1, 1, 1);
        mesh.visible = true;
        groupModels.add(mesh);
        scene.add(groupModels);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight.position.set(1000, -1000, 1000);
        directionalLight.name = "directionalLight";
        scene.add(directionalLight);
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight2.position.set(1000, 1000, 1000);
        directionalLight2.name = "directionalLight";
        scene.add(directionalLight2);
        const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1); // color, intensity, 0x000000: black
        directionalLight3.position.set(-1000, 0, 1000);
        directionalLight3.name = "directionalLight";
        scene.add(directionalLight3);

        // 별도 함수를 사용하여 바닥면 기준으로 이동 시킴 (setMesh2OriginUpper @CtxUtils.ts 를 사용)
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const bbox_size = boundingBox.getSize(new THREE.Vector3());
        const bbox_center = boundingBox.getCenter(new THREE.Vector3());
        mesh.position.sub(bbox_center);
        mesh.position.z = -boundingBox.min.z;

        // Camera Set ------------------------------------
        const cameraPosition = getNewCamPosToMesh(groupModels, cfgCommon.camera.pos);
        camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        camera.lookAt(new THREE.Vector3(0, bbox_size.z / 2, 0));

        const moveDistance = bbox_center.z * cfgCommon.scene.moveDistance;
        moveSceneToBottom(scene, camera, moveDistance);

        scene.rotation.x = -Math.PI / 2;
        renderer.render(scene, camera);

        const screenshotDataURL = renderer.domElement.toDataURL();
        const screenshotBlob = dataURLtoBlob(screenshotDataURL);

        clearScene(scene);

        const resSignedUrl = await axios.post(`/models/${model.id}/gen-thumbnail`);
        const axiosIns = axios.create();
        axiosIns
          .put(resSignedUrl.data.result.signedUrl as string, screenshotBlob)
          .then(async () => {
            ModelStore().setThumbnail(model);
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (error) {
        console.error("CTX: E001:try..catch err: An error rendering the model:", error);
      }
    });
  } catch (error) {
    console.error("CTX: E001:try..catch err: An error load the model:", error);
  }
}

function dataURLtoBlob(dataURL: any) {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}
