import io from "socket.io-client";
import {CommonStore} from "../store/common-store";
import {ModelStore} from "../store/model-store";

const socketUrl = process.env.VUE_APP_SOCKET_URL as string;
enum EventType {
  "Rejoin" = "rejoin",
  "Join" = "join",
  "CompletedAnalysis" = "completedAnalysis",
}

export const socket = io(socketUrl, {
  transports: ["websocket", "polling"],
});

export function socketJoin() {
  const userId = CommonStore().getUserId;

  socket.emit(EventType.Join, {
    userId: userId,
  });
}

socket.on(EventType.Join, (data) => {
  console.log("socket joined");
});
socket.on(EventType.Rejoin, (data) => {
  const userId = CommonStore().getUserId;

  socket.emit(EventType.Join, {
    userId: userId,
  });
});
socket.on(EventType.CompletedAnalysis, (data) => {
  console.log("socket completed analysis : " + data.modelId);
  ModelStore().setActivedModel(data.modelId);
});
