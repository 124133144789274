import {Model3D} from "./interface";

export class Part {
  count: number;
  width: number;
  height: number;
  depth: number;

  constructor(count: number, width: number, height: number, depth: number) {
    this.count = count; // 파트의 개수
    this.width = width; // 파트의 가로 크기 (x축)
    this.height = height; // 파트의 높이 (y축)
    this.depth = depth; // 파트의 세로 크기 (z축)
  }
}

// 주어진 위치에 파트를 배치할 수 있는지 확인하는 함수
function canPlace(
  part: Part,
  position: [number, number, number],
  boxDimensions: [number, number, number],
  occupiedPositions: [number, number, number][]
): boolean {
  const [px, py, pz] = position;
  const [boxWidth, , boxDepth] = boxDimensions;

  // 파트가 박스의 가로 및 세로 경계를 넘지 않는지 확인
  if (px + part.width > boxWidth || pz + part.depth > boxDepth) {
    return false;
  }

  // 파트가 다른 파트와 겹치지 않는지 확인
  for (const [ox, oy, oz] of occupiedPositions) {
    if (
      px < ox + part.width &&
      px + part.width > ox &&
      py < oy + part.height &&
      py + part.height > oy &&
      pz < oz + part.depth &&
      pz + part.depth > oz
    ) {
      return false;
    }
  }
  return true;
}

// 주어진 박스 내에 파트를 배치할 수 있는 첫 번째 위치를 찾는 함수
function placePart(
  part: Part,
  boxDimensions: [number, number, number],
  occupiedPositions: [number, number, number][]
): [number, number, number] | null {
  const [boxWidth, boxHeight, boxDepth] = boxDimensions;

  // 기존 높이 범위에서 배치를 시도
  for (let y = 0; y <= boxHeight - part.height; y++) {
    for (let z = 0; z <= boxDepth - part.depth; z++) {
      for (let x = 0; x <= boxWidth - part.width; x++) {
        if (canPlace(part, [x, y, z], boxDimensions, occupiedPositions)) {
          return [x, y, z];
        }
      }
    }
  }

  return null; // 기존 높이에서 배치할 공간이 없다면 null 반환
}

// 주어진 박스 내에 파트를 배치하고, 필요한 경우 y축의 높이를 늘려가며 배치하는 함수
function packParts(parts: Part[], boxDimensions: [number, number, number]): number {
  const occupiedPositions: [number, number, number][] = [];
  let totalHeight = 0;
  let currentBoxHeight = boxDimensions[1];

  for (const part of parts) {
    let partsLeft = part.count;

    while (partsLeft > 0) {
      let position = placePart(
        part,
        [boxDimensions[0], boxDimensions[1], boxDimensions[2]],
        occupiedPositions
      );

      // 기존 높이에서 배치할 공간이 없을 경우 y축을 늘려가며 배치
      if (position === null) {
        position = [0, currentBoxHeight, 0]; // y축을 늘려 첫 번째 위치에 배치
        currentBoxHeight += part.height; // y축 높이를 파트의 높이만큼 증가
      }

      occupiedPositions.push(position);
      partsLeft--;
      totalHeight = Math.max(totalHeight, position[1] + part.height);
    }
  }

  return totalHeight;
}

export function calculatePart(
  models: Model3D[],
  boxWidth: number,
  boxDepth: number,
  boxHeighth: number
) {
  const parts: Part[] = [];

  for (let m = 0; m < models.length; m++) {
    const model = models[m];
    parts.push(new Part(model.modelCount, model.modelSizeX, model.modelSizeY, model.modelSizeZ));
  }

  parts.sort((a, b) => b.width * b.depth * b.height - a.width * a.depth * a.height);

  const finalHeight = packParts(parts, [boxWidth, boxHeighth, boxDepth]);
  return finalHeight;
}
