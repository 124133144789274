
/******************************************
 * 하이쓰리디 - 관리자 견적서 목록 화면
 ******************************************/

import {ref, computed, defineComponent, onMounted, watch} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {toast} from "vue3-toastify";

import {OptionsStore} from "../store/options-store";
import {EstimatesStore} from "../store/estimates-store";
import {ModelStore} from "../store/model-store";

import {numberToComma, dateConvertYMDHM_KR, dateConvertYMDHM, removeCookie} from "../js/utils";

import ModalSetting from "./modals/modal-setting.vue";
import InputSearch from "../components/InputSearch.vue";
import Thumbnail from "../components/Thumbnail.vue";
import {AnalysisStatus, AnalysisStatusKo, Modals} from "../js/types";
import {CommonStore} from "../store/common-store";
import {GModalData} from "../js/interface";

export default defineComponent({
  name: "AdminEstimateList",
  components: {
    Thumbnail,
    InputSearch,
    ModalSetting,
  },
  props: {
    msg: String,
  },
  setup() {
    const commonStore = CommonStore();
    const {modalResult} = storeToRefs(commonStore);
    const optionsStore = OptionsStore();
    const estimatesStore = EstimatesStore();
    const modelsStore = ModelStore();
    const {
      estimates,
      estimateTotalCost,
      estimateTotalCount,
      estimateUnReadCount,
      estimateSubmitCount,
      estimateSearchCount,
    } = storeToRefs(estimatesStore);

    const router = useRouter();

    const allCheck = ref(false);
    const isShowContext = ref(false);
    const optionsContext = ref({
      clickClose: true,
      zIndex: 3,
      minWidth: 130,
      x: 500,
      y: 200,
      onClose: () => {
        isShowContext.value = false;
      },
    });
    const selectedDate = ref([] as Date[]);
    const isModalSetting = ref(false);

    onMounted(() => {
      optionsStore.reqGetOptions();
      estimatesStore.reqEstimates();
      modelsStore.clear();
    });

    watch(selectedDate, (value, oldValue) => {
      if (value[0] && value[1]) {
        estimatesStore.setDate(value[0], value[1]);
        estimatesStore.reqEstimates();
      }
    });
    watch(modalResult, (newV, oldV) => {
      switch (newV.modalName) {
        case Modals.SubmiEstimate:
          allCheck.value = false;
          estimatesStore.reqSubmitEstimates();
          break;
        case Modals.EstimateTrash:
          allCheck.value = false;
          estimatesStore.reqDeleteEstimates();
          break;
      }
    });

    const isShowPaging = computed(() => {
      const pageLength = process.env.VUE_APP_PAGE_LENGTH as string;
      if (estimateSearchCount.value / Number.parseInt(pageLength)) {
        return true;
      }
      if (estimateSearchCount.value % Number.parseInt(pageLength)) {
        return true;
      }

      return false;
    });

    const totalAnalysis = (analysis: string) => {
      switch (analysis) {
        case AnalysisStatus.Fail:
          return AnalysisStatusKo.Fail;
        case AnalysisStatus.Warn:
          return AnalysisStatusKo.Warn;
        case AnalysisStatus.Caution:
          return AnalysisStatusKo.Caution;
        default:
          return AnalysisStatusKo.Pass;
      }
    };

    const onGoToSetting = () => {
      isModalSetting.value = true;
    };
    const onLogout = () => {
      removeCookie("token");
      router.push({
        name: "admin-signin",
      });
    };
    const onSearch = (keyword: string) => {
      estimatesStore.setPage(0);
      estimatesStore.setKeyword(keyword);
      estimatesStore.reqEstimates();
    };
    const onPage = (pageIdx: number) => {
      console.log("estimate list pageIdx");
      estimatesStore.setPage(pageIdx - 1);
      estimatesStore.reqEstimates();
      console.log("estimate list pageIdx = " + pageIdx);
    };
    const onCancel = () => {
      estimatesStore.setPage(0);
      estimatesStore.setKeyword("");
      estimatesStore.reqEstimates();
    };
    const onDatePickerClear = () => {
      selectedDate.value = [] as Date[];
      estimatesStore.setDate(null, null);
      estimatesStore.reqEstimates();
    };
    const onContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      isShowContext.value = true;
      optionsContext.value.x = e.x;
      optionsContext.value.y = e.y;
    };
    const onGoToEstimate = (estimateId: string) => {
      router.push({
        name: "admin-estimate-detail",
        params: {
          estimateId: estimateId,
        },
      });
    };
    const onModalClose = () => {
      isModalSetting.value = false;
    };

    const onChangeAllCheck = () => {
      allCheck.value = !allCheck.value;
      estimatesStore.setAllChecked(allCheck.value);
    };
    const onChangeEsti = (estimate: any) => {
      if (!estimate.isCheck) {
        estimate["isCheck"] = true;
      } else {
        estimate.isCheck = false;
      }

      if (estimates.value.length === estimatesStore.checkedCount) {
        allCheck.value = true;
      } else {
        allCheck.value = false;
      }
    };

    const onSubmit = () => {
      if (!estimatesStore.checkedCount) {
        toast.warning("제출할 견적서를 선택해주세요.", {
          transition: toast.TRANSITIONS.SLIDE,
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const data: GModalData = {
        modalName: Modals.SubmiEstimate,
        isShowCancel: false,
        title: "확인",
        content: "견적서를 일괄 제출 하시겠습니까?",
        result: {},
      };
      CommonStore().showGlobalModal(data);
    };
    const onDelete = () => {
      if (!estimatesStore.checkedCount) {
        toast.warning("삭제할 견적서를 선택해주세요.", {
          transition: toast.TRANSITIONS.SLIDE,
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const data: GModalData = {
        modalName: Modals.EstimateTrash,
        isShowCancel: false,
        title: "확인",
        content: "견적서를 일괄 삭제 하시겠습니까?",
        result: {},
      };
      CommonStore().showGlobalModal(data);
    };

    return {
      estimates,
      estimateTotalCost,
      estimateTotalCount,
      estimateUnReadCount,
      estimateSubmitCount,
      estimateSearchCount,
      selectedDate,

      allCheck,
      isModalSetting,
      isShowPaging,
      isShowContext,
      optionsContext,

      numberToComma,
      dateConvertYMDHM,

      totalAnalysis,

      onGoToSetting,
      onLogout,
      onSearch,
      onCancel,
      onDatePickerClear,
      onContextMenu,
      onPage,
      onGoToEstimate,
      onModalClose,
      onChangeAllCheck,
      onChangeEsti,
      onSubmit,
      onDelete,
    };
  },
});
