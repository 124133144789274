
import { defineComponent } from "vue";

export default defineComponent({
  name: "CSGlobalModal",
  props: {
    isShowCancel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
});
