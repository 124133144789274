// ----------------------------------------------------------------------------------
// CtxConfig.ts
// ----------------------------------------------------------------------------------

import { Vector3 } from "three"; // Import Vector3 from the appropriate package

//-------------------------------------------------------
// 서비스가 제공되는 본 사이트
export const thisSite = {
  get appUrl(): string {
    const url = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
    return url;
  },
};

//-------------------------------------------------------

export const cfgCommon = {
  theme: "default",
  viewer: {
    wireframeColor: "#707070", // gray, RGB :#ff0000:red , #00ff00:green , #0000ff:blue
    faceColor: "#F0F0F0", // 참고사이트 모델 face색상, RGB :#ff0000:red , #00ff00:green , #0000ff:blue
    showHelpers: true,
  },
  model: {
    scale: 1, // default initial scale
    pos: new Vector3(0, 0, 0), // Use Vector3 instance
    transparent: false, // 초기 로딩시 투명여부
    opacity: 0.4, // 투명도
  },
  camera: {
    scale: 1,
    pos: new Vector3(20, 20, 20), // Use Vector3 instance // 카메라 디폴트 위치
    camModelDistance: 1, // camera와 로드된 모델간의 디폴트 거리
    cameraFov: 40, // perspective camera의 FOV (화각)
  },
  scene: {
    // 뷰어화면의 scene과 origin을 화면 아래쪽으로 이동. (3Dprinting에서는 -Z가 없음으로.
    moveDistance: 0.5, // scene의 최초 로드시, 중심점의 위치: 화면 정중앙에서 화면아래로 이동하는 스크린 거리
  },
  // Other configuration properties
};
// -------------------------------------------------------
export const cfgHelpers = {
  axis: {
    size: 10,
  },
  grid: {
    size: 10,
    division: 10,
    colorCenterLine: "#E8E8E8", // '#000000', //Default  0x444444
    colorGrid: "#f3f3f3", // 참고사이트의 그리드 색  //'#f0f0f0// '#FFFFFF', //Default  0x888888
  },
};
//-------------------------------------------------------
export const cfgGui = {
  theme: "default",
  api: {
    timeout: 5000,
  },
  model: {
    scale: 1,
    pos: new Vector3(0, 0, 0),
  },
};
export const cfgCamPreset = {
  isoMetric: new Vector3(50, 50, 50),
  top: new Vector3(0, 50, 0),
  left: new Vector3(-50, 0, 0),
  right: new Vector3(50, 0, 0),
  buttom: new Vector3(0, -50, 0),
};
//-------------------------------------------------------
// S3
export const S3ClientInfo = {
  region: process.env.VITE_S3_region,
  credentials: {
    accessKeyId: process.env.VITE_S3_accessKeyId,
    secretAccessKey: process.env.VITE_S3_secretAccessKey,
  },
};

export const GetObjectCommandInfo = {
  Bucket: process.env.VITE_S3_Bucket,
  Path: "models/",
};
//-------------------------------------------------------
// Thickness, gap warn, Caution color : ConnectStory로 부터 요청받은 규격 임
export const WarnCautionColor = {
  warn: { h: 0, s: 1.0, l: 0.55 }, // 붉은색
  caution: { h: 50, s: 1.0, l: 0.5 }, // 노랑색
};
