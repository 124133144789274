import axios from "axios";
import {defineStore} from "pinia";
import {toast} from "vue3-toastify";

/******************************************
 * 하이쓰리디 - 관리자 견적서 목록 관리 스토어
 ******************************************/

export const EstimatesStore = defineStore("estimates", {
  state: () => ({
    estimates: [] as any[],
    estimateTotalCost: 0,
    estimateTotalCount: 0,
    estimateUnReadCount: 0,
    estimateSubmitCount: 0,
    estimateSearchCount: 0,

    keyword: "",
    page: 0,
    totalPage: 0,
    startDate: null as any,
    endDate: null as any,
  }),
  getters: {
    checkedCount(): number {
      const eIds = this.estimates.filter((e) => {
        if (e.isCheck) {
          return e;
        }
      });

      return eIds.length;
    },
  },
  actions: {
    setKeyword(keyword: string) {
      this.keyword = keyword;
    },
    setPage(page: number) {
      this.page = page;
    },
    setDate(start: any, end: any) {
      this.startDate = start;
      this.endDate = end;
    },

    setAllChecked(isChecked: boolean) {
      for (let e = 0; e < this.estimates.length; e++) {
        this.estimates[e]["isCheck"] = isChecked;
      }
    },

    reqEstimates() {
      const params: any = {
        page: this.page,
      };
      if (this.keyword && this.keyword.length) {
        params["keyword"] = this.keyword;
      }
      if (this.startDate && this.endDate) {
        params["sDate"] = this.startDate;
        params["eDate"] = this.endDate;
      }

      axios
        .get("/admin/estimates", {
          params: params,
        })
        .then((r) => {
          this.estimates = r.data.estimates;
          this.estimateTotalCost = r.data.estimateTotalCost;
          this.estimateTotalCount = r.data.estimateTotalCount;
          this.estimateUnReadCount = r.data.estimateUnReadCount;
          this.estimateSubmitCount = r.data.estimateSubmitCount;
          this.estimateSearchCount = r.data.estimateSearchCount;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    reqSubmitEstimates() {
      let eIds = this.estimates.filter((e) => {
        if (e.isCheck) {
          return e;
        }
      });
      eIds = eIds.map((v) => {
        return v.id;
      });

      this.setAllChecked(false);

      axios
        .post("/admin/estimates/submit", {
          data: {
            estimateIds: eIds,
          },
        })
        .then((r) => {
          toast.success("견적서를 일괄 등록 했습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
          this.reqEstimates();
        })
        .catch((e) => {
          console.error(e);
        });
    },

    reqDeleteEstimates() {
      let eIds = this.estimates.filter((e) => {
        if (e.isCheck) {
          return e;
        }
      });
      eIds = eIds.map((v) => {
        return v.id;
      });

      this.setAllChecked(false);

      axios
        .post("/admin/estimates/delete", {
          data: {
            estimateIds: eIds,
          },
        })
        .then((r) => {
          toast.success("견적서를 일괄 삭제 했습니다.", {transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER});
          this.reqEstimates();
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
});
