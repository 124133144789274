
import {ref, computed, defineComponent, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";

import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {ModelStore} from "../store/model-store";
import {CommonStore} from "../store/common-store";

import {changeInputFile} from "../js/upload";
import {AnalysisStatus, Modals} from "../js/types";
import {numberToComma, dateConvertYMDHM_KR} from "../js/utils";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "ClientEstimate",
  components: {},
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();

    const commonStore = CommonStore();
    const {modalResult} = storeToRefs(commonStore);
    const optionsStore = OptionsStore();
    const {optionShippingCost, isEnabled} = storeToRefs(optionsStore);
    const estimateStore = EstimateStore();
    const {estimate, isEstimateSubmit} = storeToRefs(estimateStore);
    const {models, currentModel} = storeToRefs(ModelStore());
    const inputFileData = ref();

    const unit = ref("mm");
    const isDraging = ref(false);
    const isVisibleViewer = ref(false);

    const todayDate = computed(() => {
      return dateConvertYMDHM_KR(Date.now() / 1000);
    });
    const shippingCost = computed(() => {
      return numberToComma(models.value.length ? estimate.value.shippingCost : 0);
    });
    const supplyPrice = computed(() => {
      return numberToComma(
        estimate.value.buildCost +
          estimate.value.postProcessCost +
          estimate.value.paintCost +
          estimate.value.shippingCost
      );
    });
    const totalCost = computed(() => {
      const vat = Math.floor(
        (estimate.value.buildCost +
          estimate.value.postProcessCost +
          estimate.value.paintCost +
          estimate.value.shippingCost) *
          0.1
      );
      return models.value.length
        ? numberToComma(
            estimate.value.buildCost +
              estimate.value.postProcessCost +
              estimate.value.paintCost +
              estimate.value.shippingCost +
              vat
          )
        : 0;
    });
    const vat = computed(() => {
      return numberToComma(
        Math.floor(
          (estimate.value.buildCost +
            estimate.value.postProcessCost +
            estimate.value.paintCost +
            estimate.value.shippingCost) *
            0.1
        )
      );
    });

    const onPrint = () => {
      return window.print();
    };
    const onBack = () => {
      router.back();
    };

    return {
      currentModel,
      isVisibleViewer,

      AnalysisStatus,

      inputFileData,
      estimate,
      isEstimateSubmit,
      models,
      shippingCost,
      isEnabled,
      totalCost,
      todayDate,
      vat,
      supplyPrice,

      changeInputFile,
      numberToComma,
      onPrint,
      onBack,

      unit,

      isDraging,
    };
  },
});
