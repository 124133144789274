import {ref} from "vue";
import {defineStore} from "pinia";
import {useRouter, useRoute} from "vue-router";
import axios from "axios";
import {toast} from "vue3-toastify";
import {padZero, randomByNumber, removeComma} from "../js/utils";

import {CommonStore} from "./common-store";
import {OptionsStore} from "./options-store";
import {ModelStore} from "./model-store";
import {LoaderButtonStore} from "./loader-button-store";
import {
  AdminEstimateSubmit,
  Company,
  Estimate,
  EstimateSubmit,
  GModalData,
  Model3D,
} from "@/js/interface";
import {AnalysisStatus, Modals} from "@/js/types";
import {ResponseStatusCode} from "@/js/error";
import {calculatePart, Part} from "@/js/ban-parking";

/******************************************
 * 하이쓰리디 - 견적서 관리 스토어
 ******************************************/

export const EstimateStore = defineStore("Estimate", () => {
  const modelStore = ModelStore();
  const optionsStore = OptionsStore();
  const commonStore = CommonStore();
  const loaderButtonStore = LoaderButtonStore();
  const router = useRouter();
  const route = useRoute();

  const isEstimateSubmit = ref(true);

  const estimate = ref({} as Estimate);

  async function createEstimate() {
    estimate.value = {
      id: Number.parseInt(randomByNumber(9)),
      paintCost: 0,
      buildCost: 0,
      postProcessCost: 0,
      shippingCost: 0,
      discountCost: 0,
      totalCost: 0,
      vat: 0,
    };
  }

  function calculateTotalCost(isEnterCalculateBuildCost = true) {
    console.log("calculation total cost");

    if (isEnterCalculateBuildCost) {
      estimate.value.buildCost = modelStore.totalCost;
      estimate.value = JSON.parse(JSON.stringify(estimate.value));
    }

    let totalMaterialCost = 0; // 출력 비용
    let totalUseCost = 0; // 장비사용 비용
    let finalHeight = 0; // 모델 총 높이

    const otherModels: Model3D[] = [];
    const absModels: Model3D[] = [];
    const plaModels: Model3D[] = [];
    const pa12Models: Model3D[] = [];

    for (let midx = 0; midx < ModelStore().models.length; midx++) {
      const model = ModelStore().models[midx];
      const material = OptionsStore().getMaterialById(model.modelMaterialId);
      const materialName = material ? material.name : "";

      // if (AnalysisStatus.Fail === model.modelAnalysis) {
      //   continue;
      // }

      if (
        materialName.toLocaleLowerCase().includes("abs like") ||
        materialName.toLocaleLowerCase().includes("abs clear")
      ) {
        absModels.push(model);
      } else if (materialName.toLocaleLowerCase().includes("pla")) {
        plaModels.push(model);
      } else if (
        materialName.toLocaleLowerCase().includes("pa12") ||
        materialName.toLocaleLowerCase().includes("pa 12")
      ) {
        pa12Models.push(model);
      } else {
        otherModels.push(model);
      }
    }

    // 재료비 계산
    for (let i = 0; i < absModels.length; i++) {
      const model = absModels[i];
      const company = OptionsStore().getCompanyById(model.modelCompanyId);
      totalMaterialCost +=
        absModels[i].modelSizeX *
        absModels[i].modelSizeY *
        absModels[i].modelSizeZ *
        (company ? Number.parseFloat(company.costRate) : 0) *
        absModels[i].modelCount;
    }
    for (let i = 0; i < plaModels.length; i++) {
      const model = plaModels[i];
      const company = OptionsStore().getCompanyById(model.modelCompanyId);
      totalMaterialCost +=
        plaModels[i].modelVolume *
        (company ? Number.parseFloat(company.costRate) : 0) *
        plaModels[i].modelCount;
    }
    for (let i = 0; i < pa12Models.length; i++) {
      const model = pa12Models[i];
      const company = OptionsStore().getCompanyById(model.modelCompanyId);
      totalMaterialCost +=
        pa12Models[i].modelVolume *
        (company ? Number.parseFloat(company.costRate) : 0) *
        pa12Models[i].modelCount;
    }
    for (let i = 0; i < otherModels.length; i++) {
      const model = otherModels[i];
      const company = OptionsStore().getCompanyById(model.modelCompanyId);
      totalMaterialCost +=
        otherModels[i].modelSizeX *
        otherModels[i].modelSizeY *
        otherModels[i].modelSizeZ *
        (company ? Number.parseFloat(company.costRate) : 0) *
        otherModels[i].modelCount;
    }

    // 장비사용료 계산
    if (absModels.length) {
      const company = OptionsStore().getCompanyById(absModels[0].modelCompanyId);
      totalUseCost += company ? Number.parseInt(company.useCost) : 0;
    }
    if (plaModels.length) {
      const company = OptionsStore().getCompanyById(plaModels[0].modelCompanyId);
      totalUseCost += company ? Number.parseInt(company.useCost) : 0;
    }
    if (otherModels.length) {
      const company = OptionsStore().getCompanyById(otherModels[0].modelCompanyId);
      totalUseCost += company ? Number.parseInt(company.useCost) : 0;
    }
    if (pa12Models.length) {
      const parts: Part[] = [];
      for (let i = 0; i < pa12Models.length; i++) {
        parts.push(
          new Part(
            pa12Models[i].modelCount,
            pa12Models[i].modelSizeX,
            pa12Models[i].modelSizeY,
            pa12Models[i].modelSizeZ
          )
        );
      }

      const company = OptionsStore().getCompanyById(pa12Models[0].modelCompanyId) as Company;
      finalHeight = calculatePart(
        pa12Models,
        Number.parseInt(company.buildX),
        Number.parseInt(company.buildY),
        Number.parseInt(company.buildZ)
      );

      totalUseCost += Number.parseInt(company.useCost) * finalHeight;
    }

    // 100단위 올림
    const totalCost = Math.ceil((totalMaterialCost + totalUseCost) / 100) * 100;
    estimate.value.totalCost = totalCost;
    console.log("estimate total cost : " + totalCost);

    let paintCost = 0;
    let postProcessCost = 0;
    let shippingCost = 0;
    if (window.location.href.includes("/client")) {
      shippingCost = Number.parseInt(removeComma(OptionsStore().optionShippingCost));
    } else {
      paintCost = estimate.value.paintCost;
      postProcessCost = estimate.value.postProcessCost;
      shippingCost = estimate.value.shippingCost;
    }

    const totalBuildCost =
      Math.ceil((estimate.value.buildCost + paintCost + postProcessCost + shippingCost) / 100) *
      100;

    estimate.value.discountCost = totalBuildCost - totalCost;
  }

  function reqSubmit(pEstimate: EstimateSubmit) {
    if (!isEstimateSubmit.value) {
      toast.info("이미 견적서를 등록했습니다.", {
        transition: toast.TRANSITIONS.SLIDE,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    ModelStore().removeAnalysisRaw();

    axios
      .post("/estimates", pEstimate)
      .then((r) => {
        if (!r) {
          loaderButtonStore.stop();
          toast.error("네트워크가 연결이 안되있거나 서버에 연결할 수 없습니다.", {
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }

        if (ResponseStatusCode.Create_Estimate__Not_Found_Option === r.data.status) {
          loaderButtonStore.stop();
          toast.error("배송비용 설정을 찾을 수 없습니다, 관리자에게 문의하세요.", {
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }

        isEstimateSubmit.value = false;

        // 2024.08.20
        estimate.value.id = r.data.result.estimate.id;
        estimate.value.clientName = r.data.result.estimate.clientName;
        estimate.value.clientCompany = r.data.result.estimate.clientCompany;

        // toast.success("견적서를 등록했습니다, 담당자가 검토 후 연락드리겠습니다.", { autoClose: 5000, transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER });
        // CommonStore().closeModal();
        // const data: GModalData = {
        //   modalName: Modals.SubmiEstimate,
        //   title: "알림",
        //   content: `No.${estimate.id} 견적서를 등록했습니다,\n담당자가 검토 후 연락드리겠습니다.`,
        //   result: {},
        // };
        // CommonStore().showGlobalModal(data);
        CommonStore().closeModal();
        router.push({
          name: "client-estimate-submit",
        });
      })
      .catch((e) => {
        console.error(e);
        loaderButtonStore.stop();
      });

    return;
  }

  function reqSave() {
    const modelStore = ModelStore();

    const values: AdminEstimateSubmit = {
      estimate: estimate.value,
      models: modelStore.models,
    };

    axios.put(`/admin/estimates/${estimate.value.id}`, values).catch((e) => {
      console.error(e);
      loaderButtonStore.stop();
    });

    return;
  }

  function reqFinalSubmit() {
    axios
      .post(`/admin/estimates/${estimate.value.id}`, {
        estimate: estimate,
        models: ModelStore().models,
      })
      .then((r) => {
        if (!r) {
          toast.error("네트워크가 연결이 안되있거나 서버에 연결할 수 없습니다.", {
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }

        isEstimateSubmit.value = false;

        let content = `No.${estimate.value.id} 견적서를 등록했습니다.`;
        if (estimate.value.isActive) {
          content = `No.${estimate.value.id} 견적서를 수정했습니다.`;
        }

        const data: GModalData = {
          modalName: Modals.SubmiEstimate,
          title: "알림",
          content: content,
          result: {},
        };
        CommonStore().showGlobalModal(data);

        loaderButtonStore.stop();
      })
      .catch((e) => {
        console.error(e);
        loaderButtonStore.stop();
        toast.error("오류가 발생했습니다.", {
          transition: toast.TRANSITIONS.SLIDE,
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function reqGetEstimate(estimateId: string) {
    modelStore.clear();
    axios
      .get(`/admin/estimates/${estimateId}`)
      .then((r) => {
        estimate.value = r.data.estimate;
        estimate.value.buildCost = r.data.estimate.buildCost;

        for (let m = 0; m < r.data.models.length; m++) {
          r.data.models[m].isUploaded = true;
          modelStore.addModel(r.data.models[m]);
        }

        commonStore.setIsViewLoader(false);
      })
      .catch((e) => {
        router.push({
          name: "admin-estimates",
        });
      });
  }

  async function reqDelete() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/estimates/${estimate.value.id}`)
        .then((r) => {
          loaderButtonStore.stop();
          resolve(true);
        })
        .catch((e) => {
          loaderButtonStore.stop();
          reject(true);
        });
    });
  }

  return {
    isEstimateSubmit,
    estimate,
    createEstimate,
    calculateTotalCost,
    reqSubmit,
    reqFinalSubmit,
    reqGetEstimate,
    reqDelete,
    reqSave,
  };
});
