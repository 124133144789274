import {Ref, ref} from "vue";
import {storeToRefs} from "pinia";
import axios from "axios";
import tippy, {createSingleton} from "tippy.js";
import {toast} from "vue3-toastify";
import {OptionsStore} from "../store/options-store";
import {ModelStore} from "../store/model-store";
import {EstimateStore} from "../store/estimate-store";
import {Estimate, File, Model3D} from "../js/interface";
import {AnalysisStatus, MaxUploadFileSize} from "../js/types";
import {createTippySingle, numberToformatBytes} from "../js/utils";
import {CommonStore} from "@/store/common-store";

export const inputFileData: Ref<null | InputFile> = ref(null);

interface ModelRes {
  signedUrl: string;
  model: Model3D;
}

interface FileEventTarget extends EventTarget {
  files: null | [];
  value: null | string;
}

interface InputFile extends Event {
  target: FileEventTarget;
}

export const changeInputFile = async (payload: any) => {
  payload = payload as InputFile;

  if (payload.target && payload.target.files) {
    await preProcessingFileUpload(payload.target.files);
    payload.target.files = null;
    payload.target.value = null;
  }
};

export const preProcessingFileUpload = async (files: FileList) => {
  const modelStore = ModelStore();
  const {estimate} = storeToRefs(EstimateStore());

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (modelStore.checkExistByName(file.name)) {
      toast.warn(`같은 이름의 파일이 존재합니다.`, {
        transition: toast.TRANSITIONS.SLIDE,
        position: toast.POSITION.TOP_CENTER,
      });
      continue;
    }
    if (10 < modelStore.models.length + 1) {
      toast.warn(`최대 10개의 파일만 업로드 가능합니다.`, {
        transition: toast.TRANSITIONS.SLIDE,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (MaxUploadFileSize <= file.size) {
      toast.warn(`${numberToformatBytes(MaxUploadFileSize)} 이하 파일만 업로드 가능합니다.`, {
        transition: toast.TRANSITIONS.SLIDE,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      const res: ModelRes = await reqUploadSigendUrl(file, "/models/signed-url");
      const model: Model3D = modelStore.createModel(
        res.model.id,
        estimate.value.id,
        file.name,
        `${res.model.modelKey}`,
        file,
        res.signedUrl
      );
      modelStore.addModel(model);
    } catch (e) {
      console.log(e);
    }
  }
};

export const reqUploadSigendUrl = (file: File, url: string): Promise<ModelRes> => {
  return new Promise((resolve, reject) => {
    const name = file.name.normalize("NFC");

    const {estimate} = storeToRefs(EstimateStore());

    axios
      .post(url, {
        estimateId: estimate.value.id,
        name: name,
        size: file.size,
      })
      .then((r) => {
        if (!r) {
          toast.error("네트워크가 연결이 안되있거나 서버에 연결할 수 없습니다.", {
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }

        const {signedUrl, model} = r.data.result;
        return resolve({signedUrl, model});
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const binaryToArrayBuffer = (file: any): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject();
    }
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const reqUploadModel = (
  model: Model3D,
  binary: string | ArrayBuffer,
  currentPercent: Ref<number>
) => {
  return new Promise((resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const axiosIns = axios.create();
    axiosIns
      .put(model.signedUrl as string, binary, {
        onUploadProgress(event) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          currentPercent.value = percentCompleted;
        },
        cancelToken: source.token,
      })
      .then(async () => {
        resolve(model);
      })
      .catch((e) => {
        console.log(e);
        reqDeleteModel(model);
        toast.error(`${model.name} 업로드가 중지되었습니다.`);
        reject(e);
      });
  });
};

export const reqStp2Stl = (model: Model3D) => {
  return new Promise((resolve, reject) => {
    const ext = model.name.slice(model.name.lastIndexOf(".") + 1).toLowerCase();
    axios
      .post(`/models/${model.id}/stp2stl`, {
        modelId: model.id,
        modelKey: model.modelKey,
        extension: ext,
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const reqCreateThumbnail = (model: Model3D) => {
  return new Promise((resolve, reject) => {
    const ext = model.name.slice(model.name.lastIndexOf(".") + 1).toLowerCase();

    let key = model.modelKey;
    if ("stp" === ext || "STP" === ext || "step" === ext || "STEP" === ext) {
      key += ".stp.stl";
    }

    axios
      .post(`/models/${model.id}/gen-thumbnail`, {
        modelId: model.id,
        modelKey: key,
        extension: ext,
      })
      .then((r) => {
        ModelStore().setThumbnail(model);
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const reqCompletedUpload = (model: Model3D) => {
  const commonStore = CommonStore();
  const ext = model.name.slice(model.name.lastIndexOf(".") + 1).toLowerCase();

  axios
    .put(`/models/${model.id}/init`, {
      userId: commonStore.getUserId,
      unit: commonStore.getUnit,
      extension: ext,
    })
    .then(async (r) => {
      console.log("actived model: " + model.id);

      // 2024.08.21 로컬 테스트 사용
      // setTimeout(() => {
      //   ModelStore().setActivedModel(model.id);
      // }, 3000);
    })
    .catch((e) => {
      console.log(e);
      reqDeleteModel(model);
      toast.error(`${model.name} 업로드가 중지되었습니다.`);
    });
};

export const reqDeleteModel = (model: Model3D) => {
  axios
    .delete(`/models/${model.id}`)
    .then((r) => {
      ModelStore().removeModel(model.id);
    })
    .catch((e) => {
      console.log(e);
      toast.error(`${model.name} 모델 삭제를 실패했습니다.`);
    });
};
