// ----------------------------------------------------------------------------------
// CtxThickGap2Mesh.ts
// 전달 받은 verts로 face 생성 후, color는 지정 prop컬러, Mesh를 생성
///---------------------------------------------------------------------
/**
 *  전달 받은 verts로 face 생성 후, color는 지정 컬러, Mesh를 생성 
 *  input: 예) 
 *  thickWarn = [
        [
            -24.158885955810547,
            -18.641338348388672,
            23.9100341796875
        ],
        [
            -24.258888244628906,
            -8.641339302062988,
            5.0
        ],
        :
      ]
  * output: colored mesh     
 **/
// In this code, we create a mesh where each face corresponds to a point in 3D space,
// and we set the color of all faces to props.faceColor.
// We use a loop to set the color of each face based on the provided color.
// This should create a mesh with faces that are all painted with the same color,
// and each face corresponds to a point in 3D space as specified in the points prop.
// ----------------------------------------------------------------------------------
import { Vector3, BufferGeometry, Mesh, MeshBasicMaterial, Color } from "three"; // 공통
import { BufferAttribute } from "three"; // for faces
import * as THREE from "three";

//-------------------------------------------------------
export default function createThickGap2Mesh(points: number[][], faceColor: { h: number; s: number; l: number }) {
  //<1> 점 색상 칠하기 : 제외함. 이전 코드는 아래 comment 참조
  //<2> 면 색상 칠하기
  const faceColorObj = new Color();
  faceColorObj.setHSL(faceColor.h / 360, faceColor.s, faceColor.l, THREE.SRGBColorSpace);

  //[모든면] Accumulate all the points into a single array
  const vectors = points.flatMap((point) => [new Vector3(point[0], point[1], point[2])]);

  const geometry = new BufferGeometry();
  const positions = new Float32Array(vectors.length * 3);

  vectors.forEach((vector, index) => {
    positions[index * 3] = vector.x;
    positions[index * 3 + 1] = vector.y;
    positions[index * 3 + 2] = vector.z;
  });

  geometry.setAttribute("position", new BufferAttribute(positions, 3));

  // 색상
  const colorArray = new Float32Array(vectors.length * 3);

  for (let i = 0; i < colorArray.length; i += 3) {
    colorArray[i] = faceColorObj.r;
    colorArray[i + 1] = faceColorObj.g;
    colorArray[i + 2] = faceColorObj.b;
  }

  const colorAttribute = new BufferAttribute(colorArray, 3);
  geometry.setAttribute("color", colorAttribute);

  // Set up geometry and material
  const material = new MeshBasicMaterial({ vertexColors: true, side: THREE.DoubleSide });
  // const material = new MeshPhongMaterial({ vertexColors: true, side: THREE.DoubleSide })
  const mesh = new Mesh(geometry, material);

  return mesh;
}
