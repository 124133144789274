
import { ref, defineComponent } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { setCookie } from "../js/utils";
import LoaderButton from "../components/LoaderButton.vue";

export default defineComponent({
  name: "AdminSignIn",
  components: { LoaderButton },
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    const submitLogin = ref();

    let id = ref("hanyang3d");
    let password = ref("hanyangadmin");
    let errMassage = ref("");

    const onSubmit = () => {
      if (!id.value || !password.value) {
        submitLogin.value.stop();
        return (errMassage.value = "아이디 또는 비밀번호를 입력하세요.");
      }
      if (!id.value.length || !password.value.length) {
        submitLogin.value.stop();
        return (errMassage.value = "아이디 또는 비밀번호를 입력하세요.");
      }

      axios
        .post("/admin/signin", {
          id: id.value,
          password: password.value,
        })
        .then((r) => {
          console.log("admin login");

          setCookie("token", r.data.token, {
            expires: Date.now() + 86400 * 1000,
          });

          submitLogin.value.stop();

          router.push({
            name: "admin-estimates",
          });
        })
        .catch((e) => {
          console.log("fail admin login");
          if (submitLogin.value) {
            submitLogin.value.stop();
          }
        });
    };

    return { submitLogin, id, password, errMassage, onSubmit };
  },
});
