import {defineStore} from "pinia";
import {GModalData, ModalResult} from "../js/interface";
import {Modals} from "@/js/types";
import {randomByNumber} from "@/js/utils";

export const CommonStore = defineStore("common", {
  state: () => ({
    isViewLoader: false,
    isDownloadModel: false,
    //
    modalResult: {} as ModalResult,

    isShowGlobalModal: false,
    gModalData: {} as GModalData,

    isShowFormModal: false,
    cModalComponent: null as any,
    cModalData: {} as any,

    isShowTermModal: false,
    isShowPolicyModal: false,

    unit: "mm",
    currentProcess: {} as any,

    isThicknessView: false,
    isGapView: false,
    isMulitBodyView: false,
    textuerStatus: 0,
    clipingValue: 1,
    clipingMaxValue: 100,
    clipingStepValue: 50,

    userId: randomByNumber(10),
  }),
  getters: {
    getGlobalModalData: (state): GModalData => {
      return state.gModalData;
    },
    getUnit: (state): string => {
      return state.unit;
    },
    getTextuerStatus: (state) => {
      return state.textuerStatus;
    },
    getClipingValue: (state) => {
      return state.clipingValue;
    },
    getIsThicknessView: (state) => {
      return state.isThicknessView;
    },
    getIsGapView: (state) => {
      return state.isGapView;
    },
    getIsMulitBodyView: (state) => {
      return state.isMulitBodyView;
    },
    getUserId: (state) => {
      return state.userId;
    },
  },
  actions: {
    setIsViewLoader(status: boolean) {
      setTimeout(() => {
        this.isViewLoader = status;
      }, 1000);
    },
    setIsDownloadModel(status: boolean) {
      this.isDownloadModel = status;
    },

    setModalResult(modalResult: ModalResult) {
      this.modalResult = modalResult;
    },

    showGlobalModal(gModalData: GModalData) {
      this.gModalData = gModalData;
      this.isShowGlobalModal = true;
    },
    closeGlobalModal() {
      this.gModalData = {} as GModalData;
      this.isShowGlobalModal = false;
    },

    showModal(modalComponent: any, modalData: any) {
      this.cModalComponent = modalComponent;
      this.cModalData = modalData;
      this.isShowFormModal = true;
    },
    closeModal() {
      this.isShowFormModal = false;
    },

    showTermModal() {
      this.isShowTermModal = true;
    },
    showPolicyModal() {
      this.isShowPolicyModal = true;
    },
    closeTermPolicyModal() {
      this.isShowPolicyModal = false;
      this.isShowTermModal = false;
    },

    setUnit(v: string) {
      this.unit = v;
    },
    setCurrentProcess(process: any) {
      this.currentProcess = process;
    },

    setIsThicknessView(status: boolean) {
      this.isThicknessView = status;
    },
    setIsGapView(status: boolean) {
      this.isGapView = status;
    },
    setIsMulitBodyView(status: boolean) {
      this.isMulitBodyView = status;
    },
    setTextuerStatus(status: number) {
      this.textuerStatus = status;
    },
    setClipingValue(value: number) {
      this.clipingValue = value;
    },
    setClipingMaxValue(value: number) {
      this.clipingMaxValue = value;
    },
    setClipingStepValue(value: number) {
      this.clipingStepValue = value;
    },
  },
});
