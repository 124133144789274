import axios from "axios";
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";
import { useRouter, useRoute } from "vue-router";

import { LoaderButtonStore } from "../store/loader-button-store";
import { getCookie, removeCookie } from "./utils";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL + "/api";

axios.interceptors.request.use(
  function (config) {
    const token = getCookie("token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { lbtnStatus } = storeToRefs(LoaderButtonStore());
    lbtnStatus.value = false;

    if (!error.response) {
      toast.error("네트워크가 연결이 안되어있거나 서버가 응답이 없습니다.", { transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER });
      return;
    } else {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            toast.error("권한이 없습니다, 로그인 해주세요.", { transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER });
            removeCookie("token");
            window.location.href = process.env.VUE_APP_BASE_WEB_URL + "/admin/signin";
            return;
        }
      }
      if (error.response.data.message) {
        // let msg = getNetErrorMessage(error.response);
        // toast.error(error.response.data.message, { transition: toast.TRANSITIONS.SLIDE, position: toast.POSITION.TOP_CENTER });
      } else {
        // vue.$notify({
        //   group: "default",
        //   type: "error",
        //   text: msg,
        // });
      }

      return Promise.reject(error);
    }
  }
);

export default axios;
