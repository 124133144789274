
/******************************************
 * 하이쓰리디 - 관리자 견적서 상세화면
 ******************************************/

import {ref, computed, defineComponent, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";

import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {ModelStore} from "../store/model-store";
import {CommonStore} from "../store/common-store";
import {CompanyStore} from "../store/company-store";

import {changeInputFile, preProcessingFileUpload} from "../js/upload";
import {AnalysisStatus, Modals} from "../js/types";
import {numberToComma, dateConvertYMDHM, dateConvertYMDHM_KR, removeComma} from "../js/utils";

import AdminModel from "./components/admin-model.vue";
import Processes from "./components/processes.vue";
import {AdminEstimateSubmit, EstimateSubmit, GModalData, Model3D} from "../js/interface";

import ModalAnalysis from "./modals/modal-analysis.vue";
import LoaderButton from "../components/LoaderButton.vue";

export default defineComponent({
  name: "ClientEstimate",
  components: {
    AdminModel,
    Processes,
    ModalAnalysis,
    LoaderButton,
  },
  props: {
    msg: String,
  },
  setup() {
    const commonStore = CommonStore();
    const {isViewLoader, modalResult} = storeToRefs(commonStore);
    const optionsStore = OptionsStore();
    const {isEnabled} = storeToRefs(optionsStore);

    const estimateStore = EstimateStore();
    const {estimate, isEstimateSubmit} = storeToRefs(estimateStore);
    const {models, currentModel, totalQua} = storeToRefs(ModelStore());
    const inputFileData = ref();

    const router = useRouter();
    const route = useRoute();

    let unit = ref("mm");
    let isDraging = ref(false);
    let isShowProcesses = ref(false);
    const isVisibleViewer = ref(false);

    const selectedModel = ref();

    let estimateBuildCost = ref("0");
    let estimatePPCost = ref("0");
    let estimatePaintCost = ref("0");
    let estimateSippingCost = ref("0");

    const todayDate = computed(() => {
      return dateConvertYMDHM_KR(Date.now() / 1000);
    });
    const discountCost = computed(() => {
      return numberToComma(models.value.length ? estimate.value.discountCost * -1 : 0);
    });
    const totalCost = computed(() => {
      const vat = 0;
      return models.value.length ? numberToComma(estimate.value.totalCost + vat) : 0;
    });

    watch(estimate, (value, oldValue) => {
      estimateBuildCost.value = "" + numberToComma(estimate.value.buildCost);
      estimatePPCost.value = "" + numberToComma(estimate.value.postProcessCost);
      estimatePaintCost.value = "" + numberToComma(estimate.value.paintCost);
      estimateSippingCost.value = "" + numberToComma(estimate.value.shippingCost);
    });
    watch(currentModel, (newV: Model3D, oldV: Model3D) => {
      if (newV.id) {
        isVisibleViewer.value = true;
      } else {
        isVisibleViewer.value = false;
      }
    });
    watch(modalResult, async (value, oldValue) => {
      switch (value.modalName) {
        case Modals.EstimateTrash:
          CommonStore().closeGlobalModal();
          isViewLoader.value = true;
          await estimateStore.reqDelete();
          isViewLoader.value = false;
          router.push({
            name: "admin-estimates",
          });
          break;
        case Modals.SubmiEstimate:
          router.back();
          break;
      }
    });
    onMounted(async () => {
      console.log(route);
      console.log(router);

      CompanyStore().reqProcesses();

      isViewLoader.value = true;
      await optionsStore.reqGetOptions();
      estimateStore.reqGetEstimate(route.params.estimateId as string);
    });

    /************************************************************
     * Event
     ***********************************************************/
    const onUnit = (srcUnit: string) => {
      unit.value = srcUnit;
    };
    const inputFileFocus = () => {
      if (inputFileData.value) {
        inputFileData.value.click();
      }
    };
    const numberComma = (src: string) => {
      if (src.length) {
        src = src.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        if (src.length) {
          return numberToComma(Number.parseInt(removeComma(src)));
        }
      }

      return "";
    };
    const onShowCompanys = (sModel: any) => {
      selectedModel.value = sModel;
      onShowProcesses(true);
    };
    const onShowProcesses = (isShow: boolean) => {
      isShowProcesses.value = isShow;
    };

    /************************************************************
     * Drag
     ***********************************************************/
    const onDrop = (e: DragEvent) => {
      isDraging.value = false;

      e.preventDefault();

      if (e.dataTransfer) {
        let droppedFiles = e.dataTransfer.files;
        if (!droppedFiles) return;

        preProcessingFileUpload(droppedFiles);
      }
    };
    const onDragEnter = (e: DragEvent) => {
      isDraging.value = true;
    };
    const onDragLeave = (e: DragEvent) => {
      isDraging.value = false;
    };
    const onDragEnd = () => {
      isDraging.value = false;
    };

    /************************************************************
     * Submit
     ***********************************************************/
    const onGoToList = () => {
      estimateStore.reqSave();

      router.push({
        name: "admin-estimates",
      });
    };
    const onDelete = () => {
      const data: GModalData = {
        modalName: Modals.EstimateTrash,
        isShowCancel: false,
        title: "확인",
        content: "해당 견적서를 삭제하시겠습니까?",
        result: {
          estimateId: route.params.estimateId as string,
        },
      };
      CommonStore().showGlobalModal(data);
    };
    const onSubmit = () => {
      estimateStore.reqFinalSubmit();
    };
    const onPrint = () => {
      estimate.value.buildCost = Number.parseInt(removeComma(estimateBuildCost.value));
      estimate.value.shippingCost = Number.parseInt(removeComma(estimateSippingCost.value));
      estimate.value.postProcessCost = Number.parseInt(removeComma(estimatePPCost.value));
      estimate.value.paintCost = Number.parseInt(removeComma(estimatePaintCost.value));

      estimateStore.reqSave();

      router.push({
        name: "estimate-print",
      });
    };
    const onBuildCost = () => {
      estimateBuildCost.value = numberComma(estimateBuildCost.value);
      estimate.value.buildCost = Number.parseInt(removeComma(estimateBuildCost.value));
      EstimateStore().calculateTotalCost(false);
    };
    const onPostProcessCost = () => {
      estimatePPCost.value = numberComma(estimatePPCost.value);
      estimate.value.postProcessCost = Number.parseInt(removeComma(estimatePPCost.value));
      EstimateStore().calculateTotalCost(false);
    };
    const onPaintCost = () => {
      estimatePaintCost.value = numberComma(estimatePaintCost.value);
      estimate.value.paintCost = Number.parseInt(removeComma(estimatePaintCost.value));
      EstimateStore().calculateTotalCost(false);
    };
    const onShippingCost = () => {
      estimateSippingCost.value = numberComma(estimateSippingCost.value);
      estimate.value.shippingCost = Number.parseInt(removeComma(estimateSippingCost.value));
      EstimateStore().calculateTotalCost(false);
    };

    return {
      AnalysisStatus,

      inputFileData,
      estimate,
      isEstimateSubmit,
      models,
      isEnabled,
      discountCost,
      totalCost,
      todayDate,
      isShowProcesses,
      totalQua,
      isVisibleViewer,
      currentModel,
      selectedModel,

      estimateBuildCost,
      estimatePPCost,
      estimatePaintCost,
      estimateSippingCost,

      dateConvertYMDHM,
      numberComma,
      onShowCompanys,
      onShowProcesses,

      changeInputFile,
      numberToComma,
      inputFileFocus,

      unit,
      onUnit,

      isDraging,
      onDrop,
      onDragEnter,
      onDragLeave,
      onDragEnd,

      onGoToList,
      onDelete,
      onSubmit,
      onPrint,

      onBuildCost,
      onPostProcessCost,
      onPaintCost,
      onShippingCost,
    };
  },
});
