// ----------------------------------------------------------------------------------
// CtxUtils.ts
// -  주어진 메시의 최상위점을 찾기 = 클리핑 평면의 최상위점으로 사용 하기 위함
// ----------------------------------------------------------------------------------
import {Vector3, BufferGeometry, Mesh, Scene, Camera, MeshBasicMaterial, Color} from "three"; // 공통
import {BufferAttribute} from "three"; // for faces
import * as THREE from "three";
import axios from "axios";
import {Float32BufferAttribute, Points} from "three"; // for points
//  CtxConfig.ts 파일에서 초기 정보 가져오기 (호출 위치에 주의 )
import {cfgCommon, cfgHelpers, cfgCamPreset} from "./ctxConfig";

// ----------------------------------------------------------------------------------
// 공간내 중앙점으로 이동위한 좌표계산
// 입력 : mesh
// 출력 : 이동 mesh
// 로직 :<1> 입력받은 mesh의 bbox를 만들어 bbox_center를 결정.
//      <2> 로드한 현재 (mesh위치 - bbox중심점 위치)를 빼기 (subtract)하여, mesh의 X,Y 를 (0,0,z) 로 이동
//      <3> mesh를 바닥 평면 부터 위쪽 (Z+) 방향으로 위치 시키기 위해, mesh의 z 를 -boundingBox.min.z 로 지정
export function setMesh2OriginUpper(mesh: THREE.Object3D, dimemsion: any, buildMaxSize: any) {
  // This would center the mesh at `bbox_center` instead of the world origin
  // 아래와 같이 설정할 경우, mesh와 bbox는 결국 같은 위치에 있게되므로 틀린 방법임

  if (dimemsion.z > Number.parseFloat(buildMaxSize.z)) {
    if (dimemsion.z < Number.parseFloat(buildMaxSize.y)) {
      mesh.rotateX(THREE.MathUtils.degToRad(90));
    } else if (dimemsion.z < Number.parseFloat(buildMaxSize.x)) {
      mesh.rotateY(THREE.MathUtils.degToRad(90));
    }
  }

  const boundingBox = new THREE.Box3().setFromObject(mesh);
  const bbox_center = boundingBox.getCenter(new THREE.Vector3());
  mesh.position.sub(bbox_center);
  mesh.position.z = -boundingBox.min.z;
}

// ----------------------------------------------------------------------------------
// : 주어진 메시의 가로,세로,높이 정보 찾기 = 클리핑 평면의 최상위점으로 사용 하기 위함
// 아래 getHighestPos 함수를 대체하여 사용함
// 입력 : mesh
//      인자로 모델의 X,Y,Z 방향
export function getMeshDimension(mesh: THREE.Group, direction: string): number {
  const boundingBox = new THREE.Box3().setFromObject(mesh);
  let dimemsion = 0;

  switch (direction) {
    case "X":
      dimemsion = boundingBox.max.x - boundingBox.min.x;
      break;
    case "Y":
      dimemsion = boundingBox.max.y - boundingBox.min.y;
      break;
    case "Z":
      dimemsion = boundingBox.max.z - boundingBox.min.z;
      break;
    default:
      console.error(`Invalid direction: ${direction}`);
      break;
  }

  return dimemsion;
}

// ----------------------------------------------------------------------------------
// 위 함수로 대체하여 사용함
export function getHighestPos(mesh: THREE.Mesh, direction: string): number {
  // : 주어진 메시의 최상위점을 찾기 = 클리핑 평면의 최상위점으로 사용 하기 위함
  // 인자로 모델의 X,Y,Z

  let highestVertex = new THREE.Vector3();
  let highestY = -Infinity;

  const geometry = mesh.geometry;
  const positionAttribute = geometry.getAttribute("position");

  for (let i = 0; i < positionAttribute.count; i++) {
    const vertex = new THREE.Vector3();
    vertex.fromBufferAttribute(positionAttribute, i);

    if (vertex.y > highestY) {
      highestY = vertex.y;
      highestVertex = vertex.clone();
    }
  }

  return highestY;
}

// ----------------------------------------------------------------------------------
export function getNewCamPosToMesh(mesh: THREE.Group, CamPos: THREE.Vector3): THREE.Vector3 {
  // : 주어진 메시와 카메라의 거리를 일정하게 유지하기 위함.
  // input: mesh  : THREE.Mesh
  //        CamPos: THREE.Vector3
  // output: newCamPos:  THREE.Vector3

  // Calculate the bounding box of the mesh
  const boundingBox = new THREE.Box3().setFromObject(mesh);
  const size = boundingBox.getSize(new THREE.Vector3());

  const cameraFov = cfgCommon.camera.cameraFov; // 75 : from config.ts
  const camModelDistance = cfgCommon.camera.camModelDistance; // 5 : from config.ts

  // Calculate the camera position based on the distance to the mesh
  const distance = size.length() / 3 / Math.tan((Math.PI * cameraFov) / 360);
  const cameraPosition = new THREE.Vector3(distance + camModelDistance, distance + camModelDistance, distance + camModelDistance);

  // console.log("NewCamPos: ", typeof (cameraPosition), cameraPosition)

  return cameraPosition;
}

// ----------------------------------------------------------------------------------
export function moveSceneToBottom(scene: THREE.Scene, camera: THREE.Camera, moveDistance: number) {
  // : Scene을 지정 거리 만큼 아래로 이동
  // input: scene  :  THREE.Scene
  //        moveDistance: number: 이동 거리 (스크린 거리) Given height
  // output: newCamPos:  THREE.Vector3

  scene.position.y = scene.position.y - moveDistance;

  //카메라도 이동 필요
  camera.position.y -= moveDistance; // Adjust the camera position by the screen distance
  camera.position.set(camera.position.x, camera.position.y, camera.position.z);
}

// ----------------------------------------------------------------------------------
export function getBboxCenter(mesh: THREE.Mesh): THREE.Vector3 {
  // : Scene을 지정 거리 만큼 아래로 이동
  // input: scene  :  THREE.Scene
  //        moveDistance: number: 이동 거리 (스크린 거리) Given height
  // output: newCamPos:  THREE.Vector3

  // Calculate the bounding box of the mesh
  const boundingBox = new THREE.Box3().setFromObject(mesh);
  const boundingBox_center = boundingBox.getCenter(new THREE.Vector3());

  return boundingBox_center;
}

// ----------------------------------------------------------------------------------
// 모델 로드전 scene을 초기화 : CtxSTLViewer의 loader.load( 위치에서 사용 약 #311 라인 전후
// helperGroup은 삭제하지 않음.
export function clearScene(scene: THREE.Scene) {
  console.log(`before clearScene: scene.children.length : ${scene.children.length}`); // log each object as it is traversed
  scene.children.forEach((child) => {
    console.log(`Child type: ${child.type}`);
  });
  scene.children.forEach((child) => {
    if (child.name !== "helperGroup") {
      scene.remove(child);
      if (child instanceof THREE.Mesh) {
        clearThree(child);
      }
    }
  });

  console.log(`After clearScene: scene.children.length : ${scene.children.length}`); // log each object as it is traversed
}

export function clearThree(obj: THREE.Event) {
  while (obj.children.length > 0) {
    clearThree(obj.children[0]);
    obj.remove(obj.children[0]);
  }
  if (obj.geometry) obj.geometry.dispose();

  if (obj.material) {
    //in case of map, bumpMap, normalMap, envMap ...
    Object.keys(obj.material).forEach((prop) => {
      if (!obj.material[prop]) return;
      if (obj.material[prop] !== null && typeof obj.material[prop].dispose === "function") obj.material[prop].dispose();
    });
    obj.material.dispose();
  }
}
