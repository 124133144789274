export enum Paths {
  "ClientEstimate" = "/client/estimate",
}

export enum Modals {
  "None" = "none",
  "ModelTrash" = "trash",
  "EstimateTrash" = "trashEstimate",
  "SubmiEstimate" = "submitEstimate",
}

export enum AnalysisStatus {
  "Pass" = "pass",
  "Warn" = "warn",
  "Caution" = "caution",
  "Fail" = "fail",
  "Processing" = "processing",
  "Stop" = "stop",
}
export enum AnalysisStatusKo {
  "Pass" = "통과",
  "Warn" = "경고",
  "Caution" = "주의",
  "Fail" = "실패",
  "Processing" = "분석",
  "Stop" = "중지",
}

export enum Options {
  "Email" = "email",
  "DefaultPostProcessCost" = "default_postprocess_cost",
  "PostProcessCost" = "postprocess_cost",
  "DefaultPaintCost" = "default_paint_cost",
  "PaintCost" = "paint_cost",
  "ShippingCost" = "shipping_cost",
  "CautionPaint" = "caution_paint",

  "BuildSizeX" = "build_size_x",
  "BuildSizeY" = "build_size_y",
  "BuildSizeZ" = "build_size_z",
  "VolumeMultiply" = "volume_multiply",
  "MinVolume" = "normalSize_minVolume",
  "ModelMaxSize" = "model_max_size",
}

export enum AnalysisType {
  "LoadFile" = "loadFile",
  "NormalSize" = "normalSize",
  "OverVolume" = "overVolume",
  "Waterproof" = "waterproof",
  "Multibody" = "multibody",
  "BuildSize" = "buildSize",
  "Thickness" = "thickness",
  "Gap" = "gap",
  "Hollow" = "hollow",
}

export const MaxUploadFileSize = 41943040;
export const MaxUploadFileLength = 10;
