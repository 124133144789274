import { defineStore } from "pinia";

export const LoaderButtonStore = defineStore("loader-button", {
  state: () => ({
    lbtnStatus: false,
  }),
  getters: {
    getStatus: (state) => {
      return state.lbtnStatus;
    },
  },
  actions: {
    start() {
      this.lbtnStatus = true;
    },
    stop() {
      this.lbtnStatus = false;
    },
  },
});
