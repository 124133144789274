import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6783325f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "cs-container",
  style: {"height":"calc(100% - 75px)"}
}
const _hoisted_2 = { class: "relative w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelId) = $event)),
          placeholder: "modelId"
        }, null, 512), [
          [_vModelText, _ctx.modelId]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-full mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.url) = $event)),
          placeholder: "url"
        }, null, 512), [
          [_vModelText, _ctx.url]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.extension) = $event)),
          placeholder: "extension"
        }, null, 512), [
          [_vModelText, _ctx.extension]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.unit) = $event)),
          placeholder: "unit"
        }, null, 512), [
          [_vModelText, _ctx.unit]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-4 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.minVolume) = $event)),
          placeholder: "minVolume"
        }, null, 512), [
          [_vModelText, _ctx.minVolume]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.maxLength) = $event)),
          placeholder: "maxLength"
        }, null, 512), [
          [_vModelText, _ctx.maxLength]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-4 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.volumeMultiply) = $event)),
          placeholder: "volumeMultiply"
        }, null, 512), [
          [_vModelText, _ctx.volumeMultiply]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-4 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.maxBuildSizeX) = $event)),
          placeholder: "maxBuildSizeX"
        }, null, 512), [
          [_vModelText, _ctx.maxBuildSizeX]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.maxBuildSizeY) = $event)),
          placeholder: "maxBuildSizeY"
        }, null, 512), [
          [_vModelText, _ctx.maxBuildSizeY]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.maxBuildSizeZ) = $event)),
          placeholder: "maxBuildSizeZ"
        }, null, 512), [
          [_vModelText, _ctx.maxBuildSizeZ]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-4 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.thicknessWarn) = $event)),
          placeholder: "thicknessWarn"
        }, null, 512), [
          [_vModelText, _ctx.thicknessWarn]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.thicknessCaution) = $event)),
          placeholder: "thicknessCaution"
        }, null, 512), [
          [_vModelText, _ctx.thicknessCaution]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-4 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.gapWarn) = $event)),
          placeholder: "gapWarn"
        }, null, 512), [
          [_vModelText, _ctx.gapWarn]
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "block w-72 mt-2 h-8 p-2 border border-line",
          type: "text",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.gapCaution) = $event)),
          placeholder: "gapCaution"
        }, null, 512), [
          [_vModelText, _ctx.gapCaution]
        ]),
        _createElementVNode("p", null, _toDisplayString(), 1)
      ])
    ])
  ]))
}