import {Company, Material, Process} from "@/js/interface";
import {AnalysisStatus, Options} from "@/js/types";
import {numberToComma, removeComma} from "@/js/utils";
import axios from "axios";
import {defineStore} from "pinia";
import {toast} from "vue3-toastify";
import {LoaderButtonStore} from "./loader-button-store";

/******************************************************
 * 하이쓰리디 - 설정 정보
 * @description 기본 정보 및 공정 정보
 ******************************************************/

export const OptionsStore = defineStore("options", {
  state: () => ({
    optionEmail: "",
    optionDefaultPostProcessCost: "",
    optionPostProcessCost: "",
    optionDefaultPaintCost: "",
    optionPaintCost: "",
    optionShippingCost: "",
    optionCautionPaint: "",

    optionBuildSizeX: "",
    optionBuildSizeY: "",
    optionBuildSizeZ: "",
    optionVolumeMultiply: "",
    optionMinVolume: "",
    optionModelMaxSize: "",

    processes: [] as Process[],
    materials: [] as Material[],
    companys: [] as Company[],

    isEnabled: AnalysisStatus.Processing,
  }),
  getters: {
    firstProcess: (state): Process => {
      return state.processes[0];
    },
  },
  actions: {
    reqSubmit() {
      axios
        .post("/admin/options", {
          options: {
            email: this.optionEmail,
            default_postprocess_cost: removeComma(this.optionDefaultPostProcessCost),
            postprocess_cost: removeComma(this.optionPostProcessCost),
            default_paint_cost: removeComma(this.optionDefaultPaintCost),
            paint_cost: removeComma(this.optionPaintCost),
            shipping_cost: removeComma(this.optionShippingCost),
            caution_paint: this.optionCautionPaint,

            build_size_x: removeComma(this.optionBuildSizeX),
            build_size_y: removeComma(this.optionBuildSizeY),
            build_size_z: removeComma(this.optionBuildSizeZ),
            volume_multiply: this.optionVolumeMultiply,
            normalSize_minVolume: removeComma(this.optionMinVolume),
            model_max_size: removeComma(this.optionModelMaxSize),
          },
        })
        .then((r) => {
          toast.success("저장 완료.", {
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.TOP_CENTER,
          });
          LoaderButtonStore().stop();
        });
    },
    reqGetOptions() {
      return new Promise((resolve, reject) => {
        this.isEnabled = AnalysisStatus.Processing;

        axios
          .get("/options")
          .then((r) => {
            let valideOptionEmail = false;
            let valideOptionShipping = false;
            let valideProcesses = false;
            let valideMaterials = false;
            let valideCompanys = false;

            for (let o = 0; o < r.data.options.length; o++) {
              const option = r.data.options[o];

              switch (option.name) {
                case Options.Email:
                  valideOptionEmail = true;
                  this.optionEmail = option.value;
                  break;
                case Options.DefaultPostProcessCost:
                  this.optionDefaultPostProcessCost = numberToComma(option.value);
                  break;
                case Options.PostProcessCost:
                  this.optionPostProcessCost = numberToComma(option.value);
                  break;
                case Options.DefaultPaintCost:
                  this.optionDefaultPaintCost = numberToComma(option.value);
                  break;
                case Options.PaintCost:
                  this.optionPaintCost = numberToComma(option.value);
                  break;
                case Options.ShippingCost:
                  valideOptionShipping = true;
                  this.optionShippingCost = numberToComma(option.value);
                  break;
                case Options.CautionPaint:
                  this.optionCautionPaint = option.value;
                  break;

                case Options.BuildSizeX:
                  this.optionBuildSizeX = "" + numberToComma(option.value);
                  break;
                case Options.BuildSizeY:
                  this.optionBuildSizeY = "" + numberToComma(option.value);
                  break;
                case Options.BuildSizeZ:
                  this.optionBuildSizeZ = "" + numberToComma(option.value);
                  break;
                case Options.VolumeMultiply:
                  this.optionVolumeMultiply = "" + option.value;
                  break;
                case Options.MinVolume:
                  this.optionMinVolume = "" + numberToComma(option.value);
                  break;
                case Options.ModelMaxSize:
                  this.optionModelMaxSize = "" + numberToComma(option.value);
                  break;
              }
            }

            if (r.data.processes && r.data.processes.length) {
              this.processes = r.data.processes;
              valideProcesses = true;
            }
            if (r.data.materials && r.data.materials.length) {
              this.materials = r.data.materials;
              valideMaterials = true;
            }
            if (r.data.companys && r.data.companys.length) {
              this.companys = r.data.companys;
              valideCompanys = true;
            }

            if (valideOptionEmail && valideOptionShipping && valideProcesses && valideMaterials) {
              this.isEnabled = AnalysisStatus.Pass;
            } else {
              this.isEnabled = AnalysisStatus.Fail;
            }

            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            this.isEnabled = AnalysisStatus.Fail;
            reject(false);
          });
      });
    },
    reqGetCompany() {
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/processes/companys")
          .then((r) => {
            this.companys = r.data.companys;
            this.companys.forEach((val) => {
              if (val.unitCost) {
                val.unitCost = numberToComma(Number.parseInt(val.unitCost));
              }
            });
            resolve(true);
          })
          .catch((e) => {
            reject(true);
          });
      });
    },
    getProcessName(processId: number) {
      for (let p = 0; p < this.processes.length; p++) {
        if (processId === this.processes[p].id) {
          return this.processes[p].name;
        }
      }

      return null;
    },
    getProcessById(processId: number) {
      for (let p = 0; p < this.processes.length; p++) {
        if (processId === this.processes[p].id) {
          return this.processes[p];
        }
      }

      return null;
    },
    getMaterialById(materialId: number) {
      for (let p = 0; p < this.materials.length; p++) {
        if (materialId === this.materials[p].id) {
          return this.materials[p];
        }
      }

      return null;
    },
    getCompanyById(companyId: number) {
      for (let i = 0; i < this.companys.length; i++) {
        if (this.companys[i].id === companyId) {
          return this.companys[i];
        }
      }

      return null;
    },
  },
});
